.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;

  .link-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;

    .media-name-and-icon {
      display: flex;
      flex-direction: row;
    
      .media-icon {
        margin-right: 4px;
        width: 10px;
        opacity: 0.4;
      }
    }
    
    .link {
      font-size: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .delete-btn {
    align-self: center;
    position: absolute;
    right: 0;
  }
}