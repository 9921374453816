.userChoiceIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;

  .iconContainer {
    min-width: 44px;
    max-width: 44px;
    margin-right: 7px;
    margin-left: 7px;
    margin-top: 6px;

    @media only screen and (max-width: 700px) {
      margin-right: 4px;
      margin-left: 4px;
    }

  }
}

.pictures {
  .your-choice-indicator {
    position: absolute;
    z-index: 99;
    bottom: 5px;
    left: calc(25% - (30px / 2));

    @media only screen and (max-width: 700px) {
      left: calc(25% - (25px / 2));
    }

    pointer-events: none;


    svg {
      width: 30px;
      height: 30px;

      @media only screen and (max-width: 700px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  .rightSide {
    left: calc(75% - (30px /2)) !important;

    @media only screen and (max-width: 700px) {
      left: calc(75% - (25px /2)) !important;

    }
  }
}