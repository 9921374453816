.compareres-with-votes-and-user-choice-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5rem 5px;
  .any-votes {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
    padding-left: 5px;
  }
}