.sign-in-form_footer {
  margin-top: 30px;
  font-size: 1.3rem;



  .link {
    font-weight: bold;
  }
}

.signInForm{
  .customButtonShadow,
  .customButtonShadow[type="submit"],
  .customButtonShadow[type="reset"] {
    width: 100% !important;
    justify-content: center;
  }
}