.serviceUnavailable {
  font-size: 2rem;
  font-weight: 300;
  margin: auto;
}

.please-sing-up {
  white-space: nowrap;

  p {
    text-align: left;

    strong {
      font-weight: 500;
    }
  }

  ul {
    text-align: left;

    li {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.vote-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 90%;
  overflow: hidden;
  margin: 0 auto;

  strong {
    font-weight: 500;
  }

  .no-content {
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
    margin: auto;
    padding-right: 10%;
    padding-left: 10%;
    max-width: 1200px;
    line-height: 1.3;
    padding-bottom: 5%;
  }

  .vote-slider-wrapper {
    text-align: center;
  }
}