.reportIcon {
  width: 41px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 9;

  @media only screen and (max-width: 700px) {
    height: 3.6rem;
  }

  &:hover {
    filter: blur(0.5px);
  }

  &:active {
    filter: blur(2.5px);
  }
}