.account-settings {
  .importantSection {
    padding-top: 5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: -5rem;
  }

  .account-name {
    padding-top: 20px;
    font-size: 2.2rem;
    font-weight: 300;
    text-align: center;
    margin: auto;
    max-width: 85%;
    cursor: help;
    text-overflow: ellipsis;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
  }

  .tiles-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    .col {
      width: 50%;
      @media only screen and (max-width: 992px) {
        margin: auto;
        width: 100%;
      }
      .tileWrapper {
        width: 100%;
        @media only screen and (max-width: 992px) {
          margin: auto;
        }
      }
    }

    .col-left {
      .tileWrapper {
        margin-left: auto;
        @media only screen and (max-width: 992px) {
          margin: auto;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
  }
}

.account-settings.container {
  max-width: 1720px;
}