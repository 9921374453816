.sign-up-page {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1680px;
  margin: auto;
  min-height: calc(100vh - 200px);
  align-content: center;
  align-items: center;

  @media only screen and (max-width: 567px) {
    .sign-up-tile {
      border-radius: 10px;
    }
  }

  .popup {
    padding: 50px;

    @media only screen and (max-width: 700px) {
      padding: 30px;
      .popup-body{
        padding-top: 30px;
      }
      .cancelIcon {
        top: 15px;
      }
    }

    @media only screen and (max-width: 400px) {
      padding: 15px;

      .cancelIcon {
        top: 10px;
      }
    }

    .row {
      padding-top: 30px;
      display: flex;

      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }

      .col50 {
        @media only screen and (max-width: 700px) {
          width: 100%;
        }

        .popup-title {
          font-size: 1.8rem;
          text-align: left;
        }

        .popup-body {
          font-size: 1.5rem;
          text-align: left;
        }

        align-self: center;
      }
    }

    img {
      height: 400px;

      @media only screen and (max-width: 700px) {
        margin-top: 20px;
        height: 300px;
      }

      @media only screen and (max-width: 350px) {
        margin-top: 20px;
        height: auto;
        width: 100%;
      }
    }
  }

  .col50 {
    width: 50%;
    padding-right: 25px;
    padding-left: 25px;

    @media only screen and (max-width: 900px) {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;

      .sign-up-form {
        margin-top: 20px;
        padding-bottom: 20px;

        @media only screen and (max-width: 567px) {
          p {
            font-size: 1.2rem !important;
          }
        }
      }

      .sign-up-tile {
        margin: auto;

        @media only screen and (max-width: 567px) {
          margin-top: 15px;

          .title {
            padding-top: 15px;
            padding-bottom: 10px;
            font-size: 1.6rem;
          }

        }
      }
    }

    strong {
      font-weight: 500;
    }

    @media only screen and (max-width: 567px) {
      width: 100%;
      max-width: 350px;
      margin: auto;
    }
  }

  .col50.left {
    @media only screen and (max-width: 900px) {
      justify-content: center;
      justify-self: center;
      justify-items: center;
    }
  }

  .col50.right {
    text-align: right;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  h3 {
    font-size: 3.6rem;
    font-weight: 300;
    text-align: left;

    @media only screen and (max-width: 900px) {
      font-size: 2.6rem;
      text-align: center;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 567px) {
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

  .description {
    flex-direction: column;
    margin: auto;
    margin-bottom: 50px;

    a {
      text-decoration: none !important;
    }


    @media only screen and (max-width: 900px) {
      margin-bottom: 0px;

      .short-info {
        margin-top: 0px;
      }
    }

    @media only screen and (max-width: 567px) {
      padding-bottom: 0vh;
    }

    @media only screen and (min-width: 500px) and (max-height: 420px) {
      margin-top: 5vh;
    }

    p {
      font-weight: 300;
      font-size: 1.9rem;
      padding: 10px 0 20px 0;
      line-height: 1.7;

      @media only screen and (max-width: 1366px) {
        font-size: 1.8rem;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 1.4rem;
      }

      @media only screen and (max-width: 567px) {
        font-size: 1.2rem;
        padding: 5px 0 10px 0;
      }
    }


    .customButton {
      margin-left: 0px;

      @media only screen and (max-width: 1366px) {
        font-size: 1.7rem;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 1.3rem;
      }
    }
  }

}