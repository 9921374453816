.faq-page {
  .accordion-form-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10vh;
    padding: 10vh 0 10vh 0;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      padding: 0vh 0 10vh 0;
    }
  }
}
