.dropzone-wrapper {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 2px #000;
    border-image: url("../../borderDash.png") 2 round;
    width: 100%;
    max-height: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 80%;
    }

    svg {
      pointer-events: none;
    }

    .fileInput {
      display: none;
    }
  }

  .highlight {
    background-color: whitesmoke;
    animation: moveDash 1s;
    border: dashed 40px #000; // if we set 20 here, and below - instad 40, it will remove "plump rectangle effect" after animation 
    border-image: url("../../borderDash.png") 40 round;

    &:after {
      transition-duration: 1s;
      content: "";
      display: block;
      padding-bottom: calc(80% - 15px);
    }
  }

  @keyframes moveDash {
    from {
      border: dashed 2px #000;
      border-image: url("../../borderDash.png") 2 round;
    }

    to {
      border: dashed 40px #000;
      border-image: url("../../borderDash.png") 40 round;
    }
  }
}