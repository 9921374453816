.bg-img-loader {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &::after {
    display: none;
    z-index: -1;
    content: '';
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: white;
    animation: loadingRotate 1.5s infinite ease-in-out;
    border: 1px solid black;
  }

  @keyframes loadingRotate {
    100% {
      transform: rotate(360deg);
    }
  }
}

.bg-img-loader.preloaderActive {
  &::after {
    display: block !important;
  }
}