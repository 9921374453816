  .accordion-item {
    margin: 12px 0px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border: solid 1px #eeeeee;

    .wrapper {
      display: flex;
      flex-direction: row;

      .arrow-wrapper {
        position: relative;
        width: 10%;
        height: 100%;
        cursor: pointer;
        
        .arrow {  
          position: absolute;
          width: 20px;
          top: 24px;
          right: 24px;
          transform: rotate(0deg);
          transition: transform .3s ease-in-out;

          @media only screen and (max-width: 600px) {
            width: 20px;
            right: 14px;
          }

          &.is-expanded {
            transition: .5s;
            transform: rotate(180deg);
          }
        }
      } 
      
      .title {
        padding: 20px 16px;
        width: 90%;
        cursor: pointer;
        transform: translate3d(0, 0, 0);
        color: black;
        position: relative;
        font-size: 2rem;
        background: white;
        text-align: left;
        border-bottom: none;
      }
    }
  
    .content {
      overflow: hidden;
      max-height: 0;
      transition: max-height .6s;
      margin: 0;
      padding: 0px 20px;
      border-top: 0;
      background: white;
  
      div {
        margin: 0;
        padding: 20px 0;
        transition: .5s;
        opacity: 0;
        font-size: 1.4rem;
      }
      
      &.is-expanded {
        max-height: 500px;
        overflow: hidden;
        
        div { 
          opacity: 1;
        }
      }
    }
  }
  
