$sub-color: #999;
$main-color: black;

@mixin shrinkLabel {
  top: -14px;
  font-size: 1.2rem;
  color: $main-color;
}

.group.standardForm {
  position: relative;
  margin: 5vh 0 0 0;

  @media only screen and (max-width: 992px) and (min-height: 800px) {
    margin: 30px 0 0 0;
  }

  .form-input {
    font: inherit;
    background: none;
    background-color: white;
    color: $main-color;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    margin: 25px 0 0 0;

    &:focus {
      outline: none;
    }

    &-with-border {
      @extend .form-input;
      border: 1px solid $main-color;
      border-radius: 5px;
    }

    &:focus~.form-input-label {
      @include shrinkLabel();
    }

    &:focus~.form-input-label-off {
      display: none;
    }
  }

  textarea {
    resize: vertical;
    font: inherit;
    min-height: 130px;
    font-size: 1.4rem;

    &:focus~.bar:before {
      width: 100%;
    }
  }

  input {
    font: inherit;
    font-size: 1.4rem;

    &:focus~.bar:before {
      width: 100%;
    }
  }

  input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;

    &:before {
      content: '';
      height: 1.5px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $main-color;
      transition: 300ms ease all;
      left: 0%;
    }
  }

  .label-icon {
    position: absolute;
    top: 30%;
    left: 46%;
    width: 40px;
    opacity: 20%;
    transition: 300ms ease all;
    pointer-events: none;
  }
  
  .form-input-label {
    color: $sub-color;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
    
    &.shrink {
      color: $main-color;
      @include shrinkLabel();
    }
  }
}

.group.textarea {
  margin-top: 30px;
  .form-input-label {
    top: 95px;

    &.shrink {
      color: $main-color;
      top: -14px;
    }
  }
}