.account-settings {
  .underheader{
    text-align: center;
    font-size: 2rem;
  }

  .tiles-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
  }
}

.account-settings.container {
  max-width: 1720px;
}