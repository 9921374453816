.payment-status {
  .tiles-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .tileWrapper {
      width: 100%;
      min-width: auto;
      max-width: 1200px;

      .tile {
        h2 {
          font-size: 4rem;
          font-weight: 300;
          padding-bottom: 60px;
        }

        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}