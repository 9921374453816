.tileWrapper {
  width: 33.333%;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 450px;
  max-width: 550px;

  @media only screen and (max-width: 500px) {
    min-width: 0px;
    width: 100%;
  }
}

.tile {
  display: flex;
  width: 100%;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

  border-radius: 5px;
  flex-direction: column;
  align-self: center;
  padding: 14% 12%;

  .title {
    text-align: center;
    margin-top: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 24px;
    margin-bottom: 0px;
  }
}

form {
  text-align: center;
  padding-top: 20px;

  .customButton,
  .customButton[type="submit"],
  .customButton[type="reset"] {
    margin-top: 55px;

    @media only screen and (max-width: 922px) {
      margin-top: 45px;
    }

    @media only screen and (max-height: 400px) and (orientation:landscape) {
      margin-top: 40px;
    }
  }

  .customButtonShadow {
    width: 100%;
    justify-content: center;
    margin-top: 20px !important;
  }
}

.tile {
  $bg-color: #424242;
  $hl-color: black;
  $muted-color: mix(white, $bg-color, 70%);
  $trans-time: 300ms;
  $width: 100%;
  position: relative;

  .addictionalButtonTopRight {
    position: absolute;
    right: 30px;
    top: 30px;
    height: auto;
    width: 5.5%;
    min-width: 20px;

    img {
      width: 100%;
      cursor: pointer;

      &:hover {
        filter: blur(0.5px);
      }

      &:active {
        filter: blur(2.5px);
      }
    }
  }

  .collapsible-menu-wrapper {
    .collapsible-menu {
      &:before {
        left: auto;
        right: 5px;

        @media only screen and (max-width: 1700px) {
          right: 4px;
        }

        @media only screen and (max-width: 1500px) {
          right: 3px;
        }
      }
    }
  }
}