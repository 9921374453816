.context-edit-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 36px;
  position: relative;
  transition-duration: 0.2s;
  width: 100%;
  max-width: 600px;
  padding: 0 24px 0 24px;

  .context-icon {
    position: absolute;
    left: 44%;
    top: -22%;
    z-index: 8;
  }

  .icon-wrapper {
    cursor: pointer;
    position: absolute;
    width: 36px;
    background: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    right: 7%;
    top: 23px;
    filter: blur(0px);

    &:hover {
      filter: blur(0.5px);
    }

    &:active {
      filter: blur(2.5px);
    }
  }

  .context-edit {
    padding: 12px 12px 12px 12px;
    left: 0; 
    right: 0;
    top: 30px;
    margin-left: auto; 
    margin-right: auto; 
    background: white;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    line-height: 150%;
    border: 0.8px solid black;
    
    .form-input {
      width: 100%;
      height: 100%;
      border: none;
      resize: none;
      outline: none;
      background: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.55rem;
      letter-spacing: 1px;
      &-disabled {
        @extend .form-input;
        color: rgb(179, 171, 171);
      }
    }
  }

  .none {
    display: none;
  }
}