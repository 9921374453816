.scope {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 60px;

  .line-wrapper {
    position: relative;

    .line {
      position: relative;
      border-top: 1px solid #8c8b8b;
      top: 20px;
    }

    .circle {
      width: 35px;
      height: 35px;
      background-color: white; //#62bd19;
      border-radius: 50%;
      position: absolute;
      top: 7px;
    }
  }
}