.customButton,
.customButton[type="submit"],
.customButton[type="reset"] {
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  margin: auto;
  border: 1px solid black;
  border-radius: 75px;
  background: white;
  padding: 7px 45px;
  font-size: 1.8rem;
  font-weight: 400;
  user-select: none;
  align-items: center;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.015);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.03);
  }

  img {
    position: absolute;
    right: 4%;
  }
}

.customButton:disabled,
.customButton[disabled]{
  color: #ccc;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.015);

}