.pageTitleBar {
  width: 100%;
  text-align: center;
  user-select: none;
  padding-top: 6vh;

  .header {
    font-weight: 300;
    font-size: 2.7rem;
    letter-spacing: 0.05em;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
    word-wrap: break-word;
    @media only screen and (max-width: 700px) {
      font-size: 2.2rem;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}