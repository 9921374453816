html{
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1366px) {
  html{
    font-size: 9px;
  }
}

@media only screen and (max-width: 812px) {
  html{
    font-size: 8px;
  }
}

*,
:before,
:after {
  box-sizing: border-box;
}
body {
  margin: 0px;
  overflow-y: scroll;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 1;
  -webkit-animation: showPage 0.8s;
          animation: showPage 0.8s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

@-webkit-keyframes showPage {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes showPage {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

footer {
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-bottom: 33px;
  position: relative;
  bottom: 0; }

*::-webkit-scrollbar-track {
  background-color: #F5F5F5; }

*::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5; }

*::-webkit-scrollbar-thumb {
  background-color: #000000; }

.app-pages {
  margin-top: 80px;
  min-height: calc(100vh - 130px);
  padding-bottom: 30px; }
  @media only screen and (max-width: 700px) {
    .app-pages {
      margin-top: 70px;
      min-height: calc(100vh - 120px); } }
  @media only screen and (max-height: 400px) {
    .app-pages {
      margin-top: 60px;
      min-height: calc(100vh - 110px); } }

a {
  text-decoration: none;
  color: black; }
  a:hover {
    text-decoration: underline; }
  a:active {
    -webkit-filter: blur(1px);
            filter: blur(1px); }

@media only screen and (max-width: 400px) {
  .toasts-container {
    width: 80% !important; } }

.toasts-container .toast {
  -webkit-animation-duration: 0.3s !important;
          animation-duration: 0.3s !important;
  font-size: 1.3rem;
  margin-right: 20px;
  border-radius: 100px !important;
  padding: 7px 45px !important;
  color: white !important;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 400px) {
    .toasts-container .toast {
      width: calc(100% - 50px) !important;
      justify-content: center !important;
      margin: auto !important;
      padding: 5px 30px !important;
      min-height: 40px !important; } }

.container {
  max-width: 1680px;
  margin: auto; }

* {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; }

.navbar.showShadow {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03); }

.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  background: white;
  z-index: 100; }
  @media only screen and (max-width: 700px) {
    .navbar {
      height: 70px; } }
  @media only screen and (max-height: 400px) {
    .navbar {
      height: 60px; } }
  .navbar .container {
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    padding-right: 20px;
    padding-left: 20px; }
    @media only screen and (max-width: 700px) {
      .navbar .container {
        padding-right: 10px;
        padding-left: 10px;
        height: 70px; } }
    @media only screen and (max-height: 400px) {
      .navbar .container {
        height: 60px; } }
    .navbar .container .col-left {
      width: 30%; }
      @media only screen and (max-width: 700px) {
        .navbar .container .col-left {
          width: 70%; } }
      .navbar .container .col-left img {
        height: 45px;
        width: auto;
        padding: 0px 3px; }
        .navbar .container .col-left img:hover {
          -webkit-filter: blur(0.5px);
                  filter: blur(0.5px); }
        .navbar .container .col-left img:active {
          -webkit-filter: blur(2.5px);
                  filter: blur(2.5px); }
        @media only screen and (max-width: 700px) {
          .navbar .container .col-left img {
            height: auto;
            width: 70%; }
            .navbar .container .col-left img:hover {
              -webkit-filter: none;
                      filter: none; } }
        @media only screen and (max-width: 400px) {
          .navbar .container .col-left img {
            width: 90%; } }
        @media only screen and (max-height: 400px) and (max-width: 812px) {
          .navbar .container .col-left img {
            height: 40px;
            width: auto; } }
    .navbar .container .col-middle {
      width: 40%;
      text-align: center; }
      @media only screen and (max-width: 700px) {
        .navbar .container .col-middle {
          display: none; } }
    .navbar .container .col-right {
      width: 30%;
      display: flex;
      justify-content: flex-end; }
      .navbar .container .col-right .iconWrapper_points:hover {
        -webkit-filter: blur(0.5px);
                filter: blur(0.5px); }
      .navbar .container .col-right .iconWrapper_points:active {
        -webkit-filter: blur(2.5px);
                filter: blur(2.5px); }
      .navbar .container .col-right .iconWrapper_points .points-count-wrapper {
        overflow: hidden;
        width: 45px;
        height: 45px;
        border-radius: 100px; }
        @media only screen and (max-width: 700px) {
          .navbar .container .col-right .iconWrapper_points .points-count-wrapper {
            height: 4rem;
            width: 4rem; }
            .navbar .container .col-right .iconWrapper_points .points-count-wrapper:hover {
              -webkit-filter: none;
                      filter: none; } }
        @media only screen and (max-height: 400px) and (max-width: 812px) {
          .navbar .container .col-right .iconWrapper_points .points-count-wrapper {
            height: 38px;
            width: 38px; } }
        .navbar .container .col-right .iconWrapper_points .points-count-wrapper a {
          text-decoration: none !important; }
      .navbar .container .col-right .points-count {
        font-weight: 300;
        text-align: center;
        line-height: 44px;
        font-size: 1.75rem;
        letter-spacing: 0.05em; }
        @media only screen and (max-width: 700px) {
          .navbar .container .col-right .points-count {
            line-height: 4rem; } }
        @media only screen and (max-height: 400px) and (max-width: 812px) {
          .navbar .container .col-right .points-count {
            line-height: 38px; } }
      .navbar .container .col-right .addPointsAnim {
        fill: green;
        -webkit-animation: changePoints 0.7s ease-in-out;
                animation: changePoints 0.7s ease-in-out; }

@-webkit-keyframes changePoints {
  0% {
    opacity: 1;
    padding-left: 0px;
    padding-right: 0px; }
  49% {
    padding-right: 50px;
    padding-left: 0px;
    opacity: 0; }
  51% {
    padding-right: 0px;
    padding-left: 50px;
    opacity: 0; }
  100% {
    padding-left: 0px;
    padding-right: 0px;
    opacity: 1; } }

@keyframes changePoints {
  0% {
    opacity: 1;
    padding-left: 0px;
    padding-right: 0px; }
  49% {
    padding-right: 50px;
    padding-left: 0px;
    opacity: 0; }
  51% {
    padding-right: 0px;
    padding-left: 50px;
    opacity: 0; }
  100% {
    padding-left: 0px;
    padding-right: 0px;
    opacity: 1; } }
      .navbar .container .col-right .iconWrapper {
        padding: 6px; }
        .navbar .container .col-right .iconWrapper .user-details {
          text-transform: uppercase;
          font-weight: 500; }
        .navbar .container .col-right .iconWrapper img {
          cursor: pointer;
          height: 45px;
          width: auto;
          -webkit-filter: blur(0px);
                  filter: blur(0px); }
          .navbar .container .col-right .iconWrapper img:hover {
            -webkit-filter: blur(0.5px);
                    filter: blur(0.5px); }
          .navbar .container .col-right .iconWrapper img:active {
            -webkit-filter: blur(2.5px);
                    filter: blur(2.5px); }
          @media only screen and (max-width: 700px) {
            .navbar .container .col-right .iconWrapper img {
              height: 4rem; } }
          @media only screen and (max-height: 400px) and (max-width: 812px) {
            .navbar .container .col-right .iconWrapper img {
              height: 38px; } }
    .navbar .container .unautorized {
      font-size: 1.4rem;
      padding: 3px;
      margin-left: auto; }
      @media only screen and (max-width: 400px) {
        .navbar .container .unautorized {
          flex-direction: column-reverse; } }
      .navbar .container .unautorized .break {
        margin: auto 0px auto 10px;
        padding: 8px 0px 8px 0px;
        color: #bbb; }
        @media only screen and (max-width: 400px) {
          .navbar .container .unautorized .break {
            display: none; } }
      .navbar .container .unautorized .aWrapper {
        margin-left: 10px;
        text-align: center;
        white-space: nowrap; }
        .navbar .container .unautorized .aWrapper:first-of-type {
          margin: 8px 0px; }
        .navbar .container .unautorized .aWrapper:last-of-type {
          padding: 8px 12px;
          border: 1px solid black;
          border-radius: 5px; }
          @media only screen and (max-width: 400px) {
            .navbar .container .unautorized .aWrapper:last-of-type {
              text-decoration: underline; } }
        @media only screen and (max-width: 400px) {
          .navbar .container .unautorized .aWrapper {
            margin: 4px 0px !important;
            font-weight: 1.2rem !important;
            padding: 0px 4px !important;
            border: none !important;
            text-align: right !important; } }
      .navbar .container .unautorized .aWrapper.disabled {
        cursor: not-allowed; }
        .navbar .container .unautorized .aWrapper.disabled a {
          pointer-events: none; }
    @media only screen and (max-width: 700px) and (min-height: 400px) {
      .navbar .container .col {
        width: 50%;
        padding: 3.5%; } }

.collapsible-menu-wrapper {
  background: black;
  display: flex;
  width: inherit;
  position: relative;
  justify-content: center; }
  .collapsible-menu-wrapper .collapsible-menu {
    margin-top: 10px;
    position: absolute;
    border: 1px solid black;
    border-radius: 5px;
    background: white;
    max-width: 300px;
    padding: 30% 60%;
    right: 1px; }
    .collapsible-menu-wrapper .collapsible-menu .link-wrapper {
      text-align: center;
      font-size: 1.3rem;
      padding: 8px 0px;
      cursor: pointer; }
      .collapsible-menu-wrapper .collapsible-menu .link-wrapper :hover {
        text-decoration: underline; }
      .collapsible-menu-wrapper .collapsible-menu .link-wrapper span {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        white-space: nowrap; }
        .collapsible-menu-wrapper .collapsible-menu .link-wrapper span:hover {
          text-decoration: underline; }
        .collapsible-menu-wrapper .collapsible-menu .link-wrapper span:active {
          -webkit-filter: blur(1px);
                  filter: blur(1px); }
          @media only screen and (max-width: 800px) {
            .collapsible-menu-wrapper .collapsible-menu .link-wrapper span:active {
              -webkit-filter: blur(0px);
                      filter: blur(0px); } }
    .collapsible-menu-wrapper .collapsible-menu .position-over {
      -webkit-transform: scaleY(-1);
              transform: scaleY(-1); }
    .collapsible-menu-wrapper .collapsible-menu .user-details {
      text-align: center;
      font-size: 1.3rem;
      padding: 8px 0px 8px;
      margin-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      border-bottom: 1px solid #cacaca; }
    .collapsible-menu-wrapper .collapsible-menu:before {
      left: auto;
      right: 14px;
      top: -16px;
      content: "";
      display: inline-block;
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: #1b1f23; }
      @media only screen and (max-width: 700px) {
        .collapsible-menu-wrapper .collapsible-menu:before {
          right: 6px; } }
    .collapsible-menu-wrapper .collapsible-menu a {
      white-space: nowrap; }

.aesthetic-indicator {
  display: flex;
  align-content: center;
  align-self: center;
  align-items: center;
  height: 46px;
  cursor: help; }
  @media only screen and (max-width: 700px) {
    .aesthetic-indicator {
      height: 4rem;
      display: none; } }
  @media only screen and (max-height: 400px) and (max-width: 812px) {
    .aesthetic-indicator {
      height: 38px; } }
  .aesthetic-indicator .individual,
  .aesthetic-indicator .majority {
    margin-top: auto; }
    .aesthetic-indicator .individual img,
    .aesthetic-indicator .majority img {
      width: 45px;
      height: 45px; }
      @media only screen and (max-width: 700px) {
        .aesthetic-indicator .individual img,
        .aesthetic-indicator .majority img {
          height: 4rem;
          width: 4rem; } }
      @media only screen and (max-height: 400px) and (max-width: 812px) {
        .aesthetic-indicator .individual img,
        .aesthetic-indicator .majority img {
          height: 38px;
          width: 38px; } }
  .aesthetic-indicator .progress-bar-wrapper {
    width: 100%; }
    .aesthetic-indicator .progress-bar-wrapper .progress-bar {
      border-bottom: 0.8px solid black;
      height: 1px;
      margin-right: 18px;
      margin-left: 18px;
      width: calc(100% - 36px);
      display: flex;
      flex-direction: column;
      position: relative; }
      @media only screen and (max-width: 700px) {
        .aesthetic-indicator .progress-bar-wrapper .progress-bar {
          margin-right: 14px;
          margin-left: 14px;
          width: calc(100% - 28px); } }
      .aesthetic-indicator .progress-bar-wrapper .progress-bar .hotIndicator {
        min-width: 36px;
        max-width: 36px;
        position: absolute;
        -webkit-transform: translateY(-18px) translateX(-18px);
                transform: translateY(-18px) translateX(-18px);
        -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }
        @media only screen and (max-width: 700px) {
          .aesthetic-indicator .progress-bar-wrapper .progress-bar .hotIndicator {
            min-width: 28px;
            max-width: 28px;
            -webkit-transform: translateY(-14px) translateX(-14px);
                    transform: translateY(-14px) translateX(-14px); } }

.user-compareres .tiles-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .user-compareres .tiles-wrapper .any-compareres {
    font-size: 2rem;
    font-weight: 300; }
    .user-compareres .tiles-wrapper .any-compareres .addIcon {
      height: 15px;
      margin-bottom: -2px; }

.user-compareres.container {
  max-width: 1720px; }

.copyIcon {
  position: absolute;
  right: 0;
  top: 9px;
  width: 26px;
  cursor: pointer; }

.shareInputWrapper .group .form-input {
  padding-right: 35px; }

.user-comparere-tile .user_comparere_link-wrapper:active {
  -webkit-filter: blur(1px);
          filter: blur(1px); }

.tile {
  margin-bottom: 4rem;
  text-align: center; }
  .tile .smallTitle {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.05em; }
  .tile .votesCount {
    font-size: 4rem;
    font-weight: 200;
    letter-spacing: 0.05em;
    margin-bottom: 30px;
    margin-top: 5px; }
  .tile .selector {
    margin-top: 15px;
    margin-bottom: 3px;
    margin-right: 20px;
    margin-left: -20px;
    display: flex; }
    .tile .selector .selectorItem {
      position: relative;
      width: 40px;
      font-weight: 400;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .tile .selector .selectorItem div {
        font-weight: 300;
        margin: auto; }
      .tile .selector .selectorItem img {
        margin: auto;
        width: 26px; }
  .tile .pictures {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    height: 260px;
    z-index: 2; }
    @media only screen and (max-width: 700px) {
      .tile .pictures {
        height: 220px; } }
    @media only screen and (max-width: 340px) {
      .tile .pictures {
        height: 180px; } }
    .tile .pictures .picture {
      width: calc(50% - 2px);
      height: 260px;
      margin: 1px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-transition-duration: 1s;
              transition-duration: 1s;
      cursor: -webkit-zoom-out;
      cursor: zoom-out; }
      @media only screen and (max-width: 700px) {
        .tile .pictures .picture {
          height: 220px; } }
      @media only screen and (max-width: 340px) {
        .tile .pictures .picture {
          height: 180px; } }
      .tile .pictures .picture:active {
        -webkit-transition-duration: 1s;
                transition-duration: 1s;
        background-size: contain;
        cursor: -webkit-zoom-in;
        cursor: zoom-in; }
        .tile .pictures .picture:active::after {
          background: white; }

.tileWrapper {
  width: 33.333%;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 450px;
  max-width: 550px; }
  @media only screen and (max-width: 500px) {
    .tileWrapper {
      min-width: 0px;
      width: 100%; } }

.tile {
  display: flex;
  width: 100%;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex-direction: column;
  align-self: center;
  padding: 14% 12%; }
  .tile .title {
    text-align: center;
    margin-top: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 24px;
    margin-bottom: 0px; }

form {
  text-align: center;
  padding-top: 20px; }
  form .customButton,
  form .customButton[type="submit"],
  form .customButton[type="reset"] {
    margin-top: 55px; }
    @media only screen and (max-width: 922px) {
      form .customButton,
      form .customButton[type="submit"],
      form .customButton[type="reset"] {
        margin-top: 45px; } }
    @media only screen and (max-height: 400px) and (orientation: landscape) {
      form .customButton,
      form .customButton[type="submit"],
      form .customButton[type="reset"] {
        margin-top: 40px; } }
  form .customButtonShadow {
    width: 100%;
    justify-content: center;
    margin-top: 20px !important; }

.tile {
  position: relative; }
  .tile .addictionalButtonTopRight {
    position: absolute;
    right: 30px;
    top: 30px;
    height: auto;
    width: 5.5%;
    min-width: 20px; }
    .tile .addictionalButtonTopRight img {
      width: 100%;
      cursor: pointer; }
      .tile .addictionalButtonTopRight img:hover {
        -webkit-filter: blur(0.5px);
                filter: blur(0.5px); }
      .tile .addictionalButtonTopRight img:active {
        -webkit-filter: blur(2.5px);
                filter: blur(2.5px); }
  .tile .collapsible-menu-wrapper .collapsible-menu:before {
    left: auto;
    right: 5px; }
    @media only screen and (max-width: 1700px) {
      .tile .collapsible-menu-wrapper .collapsible-menu:before {
        right: 4px; } }
    @media only screen and (max-width: 1500px) {
      .tile .collapsible-menu-wrapper .collapsible-menu:before {
        right: 3px; } }

.popup-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  z-index: 999; }
  .popup-overlay .popup {
    flex-direction: column;
    flex-grow: inherit;
    height: auto;
    margin: auto;
    width: calc(100% - 10px);
    max-width: 850px;
    padding: 8rem 12rem;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    max-height: calc(100vh - 10px);
    overflow-y: auto;
    position: relative; }
    @media only screen and (max-width: 1400px) {
      .popup-overlay .popup {
        padding: 6rem 6rem; } }
    @media only screen and (max-width: 700px) {
      .popup-overlay .popup {
        padding: 6rem 3rem; } }
    .popup-overlay .popup .cancelIcon {
      position: absolute;
      top: 30px;
      right: 28px;
      width: 15px;
      height: auto;
      cursor: pointer; }
      .popup-overlay .popup .cancelIcon:hover {
        -webkit-filter: blur(0.5px);
                filter: blur(0.5px); }
      .popup-overlay .popup .cancelIcon:active {
        -webkit-filter: blur(2.5px);
                filter: blur(2.5px); }
    .popup-overlay .popup .popup-title {
      font-size: 2rem;
      font-weight: 400; }
    .popup-overlay .popup .popup-body {
      padding-top: 50px;
      font-size: 1.8rem;
      font-weight: 300; }
    .popup-overlay .popup .popup-footer {
      padding-top: 50px;
      display: flex;
      justify-content: center; }
      .popup-overlay .popup .popup-footer button {
        margin: 3px 13px; }

.customButton,
.customButton[type="submit"],
.customButton[type="reset"] {
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  margin: auto;
  border: 1px solid black;
  border-radius: 75px;
  background: white;
  padding: 7px 45px;
  font-size: 1.8rem;
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  position: relative; }
  .customButton:hover,
  .customButton[type="submit"]:hover,
  .customButton[type="reset"]:hover {
    background-color: rgba(0, 0, 0, 0.015); }
  .customButton:active,
  .customButton[type="submit"]:active,
  .customButton[type="reset"]:active {
    background-color: rgba(0, 0, 0, 0.03); }
  .customButton img,
  .customButton[type="submit"] img,
  .customButton[type="reset"] img {
    position: absolute;
    right: 4%; }

.customButton:disabled,
.customButton[disabled] {
  color: #ccc;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.015); }

.popup-overlay, .popup-overlay-absolute {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center; }
  .popup-overlay-absolute {
    position: absolute;
    height: 80vh;
    -webkit-transform: translateX(-48vw) translateY(5vh);
            transform: translateX(-48vw) translateY(5vh);
    width: calc(100vw - 20px); }

.group.standardForm {
  position: relative;
  margin: 5vh 0 0 0; }
  @media only screen and (max-width: 992px) and (min-height: 800px) {
    .group.standardForm {
      margin: 30px 0 0 0; } }
  .group.standardForm .form-input, .group.standardForm .form-input-with-border {
    font: inherit;
    background: none;
    background-color: white;
    color: black;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    margin: 25px 0 0 0; }
    .group.standardForm .form-input:focus, .group.standardForm .form-input-with-border:focus {
      outline: none; }
    .group.standardForm .form-input-with-border {
      border: 1px solid black;
      border-radius: 5px; }
    .group.standardForm .form-input:focus ~ .form-input-label, .group.standardForm .form-input-with-border:focus ~ .form-input-label {
      top: -14px;
      font-size: 1.2rem;
      color: black; }
    .group.standardForm .form-input:focus ~ .form-input-label-off, .group.standardForm .form-input-with-border:focus ~ .form-input-label-off {
      display: none; }
  .group.standardForm textarea {
    resize: vertical;
    font: inherit;
    min-height: 130px;
    font-size: 1.4rem; }
    .group.standardForm textarea:focus ~ .bar:before {
      width: 100%; }
  .group.standardForm input {
    font: inherit;
    font-size: 1.4rem; }
    .group.standardForm input:focus ~ .bar:before {
      width: 100%; }
  .group.standardForm input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400; }
  .group.standardForm .bar {
    position: relative;
    display: block;
    width: 100%; }
    .group.standardForm .bar:before {
      content: '';
      height: 1.5px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: black;
      -webkit-transition: 300ms ease all;
      transition: 300ms ease all;
      left: 0%; }
  .group.standardForm .label-icon {
    position: absolute;
    top: 30%;
    left: 46%;
    width: 40px;
    opacity: 20%;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
    pointer-events: none; }
  .group.standardForm .form-input-label {
    color: #999;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all; }
    .group.standardForm .form-input-label.shrink {
      color: black;
      top: -14px;
      font-size: 1.2rem;
      color: black; }

.group.textarea {
  margin-top: 30px; }
  .group.textarea .form-input-label {
    top: 95px; }
    .group.textarea .form-input-label.shrink {
      color: black;
      top: -14px; }

.bg-img-loader {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }
  .bg-img-loader::after {
    display: none;
    z-index: -1;
    content: '';
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: white;
    -webkit-animation: loadingRotate 1.5s infinite ease-in-out;
            animation: loadingRotate 1.5s infinite ease-in-out;
    border: 1px solid black; }

@-webkit-keyframes loadingRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bg-img-loader.preloaderActive::after {
  display: block !important; }

.pageTitleBar {
  width: 100%;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 6vh; }
  .pageTitleBar .header {
    font-weight: 300;
    font-size: 2.7rem;
    letter-spacing: 0.05em;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
    word-wrap: break-word; }
    @media only screen and (max-width: 700px) {
      .pageTitleBar .header {
        font-size: 2.2rem;
        padding-right: 10px;
        padding-left: 10px; } }

.account-settings .importantSection {
  padding-top: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -5rem; }

.account-settings .account-name {
  padding-top: 20px;
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  margin: auto;
  max-width: 85%;
  cursor: help;
  text-overflow: ellipsis;
  width: auto;
  overflow: hidden;
  white-space: nowrap; }

.account-settings .tiles-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 992px) {
    .account-settings .tiles-wrapper {
      flex-direction: column; } }
  .account-settings .tiles-wrapper .col {
    width: 50%; }
    @media only screen and (max-width: 992px) {
      .account-settings .tiles-wrapper .col {
        margin: auto;
        width: 100%; } }
    .account-settings .tiles-wrapper .col .tileWrapper {
      width: 100%; }
      @media only screen and (max-width: 992px) {
        .account-settings .tiles-wrapper .col .tileWrapper {
          margin: auto; } }
  .account-settings .tiles-wrapper .col-left .tileWrapper {
    margin-left: auto; }
    @media only screen and (max-width: 992px) {
      .account-settings .tiles-wrapper .col-left .tileWrapper {
        margin: auto; } }
  @media only screen and (max-width: 992px) {
    .account-settings .tiles-wrapper {
      justify-content: center; } }

.account-settings.container {
  max-width: 1720px; }

.group-oval.standardForm {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px; }
  .group-oval.standardForm .form-input {
    font: inherit;
    background: none;
    background-color: white;
    color: black;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 11.5px 11px;
    display: block;
    width: 100%;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
    margin: 25px 0 0 0;
    border-radius: 20px; }
    .group-oval.standardForm .form-input:disabled {
      background: #fafafa; }
    .group-oval.standardForm .form-input:focus {
      outline: none; }
    .group-oval.standardForm .form-input:hover {
      box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.15); }
    .group-oval.standardForm .form-input:focus ~ .form-input-label-off {
      display: none; }
  .group-oval.standardForm .label-icon {
    position: absolute;
    top: 40%;
    left: 46%;
    width: 40px;
    opacity: 20%;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
    pointer-events: none; }
  .group-oval.standardForm textarea {
    resize: vertical;
    font: inherit;
    min-height: 130px;
    font-size: 1.4rem; }
    .group-oval.standardForm textarea:focus ~ .bar:before {
      width: 100%; }
  .group-oval.standardForm input {
    font: inherit;
    font-size: 1.4rem; }
  .group-oval.standardForm input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400; }
  .group-oval.standardForm .form-input-label {
    color: #999;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 14px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all; }
    .group-oval.standardForm .form-input-label.shrink {
      color: black;
      top: -19px;
      font-size: 1.2rem;
      color: black; }

.group.textarea {
  margin-top: 30px; }
  .group.textarea .form-input-label {
    top: 95px; }
    .group.textarea .form-input-label.shrink {
      color: black;
      top: -14px; }

.customButtonShadow,
.customButtonShadow[type="submit"],
.customButtonShadow[type="reset"] {
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 75px;
  background: white;
  padding: 10px 45px;
  font-size: 1.6rem;
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15); }
  .customButtonShadow:hover,
  .customButtonShadow[type="submit"]:hover,
  .customButtonShadow[type="reset"]:hover {
    background-color: rgba(0, 0, 0, 0.015); }
  .customButtonShadow:active,
  .customButtonShadow[type="submit"]:active,
  .customButtonShadow[type="reset"]:active {
    background-color: rgba(0, 0, 0, 0.03); }
  .customButtonShadow img,
  .customButtonShadow[type="submit"] img,
  .customButtonShadow[type="reset"] img {
    position: absolute;
    right: 4%; }

.customButtonShadow:disabled,
.customButtonShadow[disabled] {
  color: #ccc;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.015); }

.group-input-checkbox-shadow {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex; }
  .group-input-checkbox-shadow .styled-checkbox {
    position: absolute;
    font-size: 1.3rem;
    opacity: 0;
    height: 23px; }
    .group-input-checkbox-shadow .styled-checkbox + label {
      height: 23px;
      text-align: left;
      font-size: 1.3rem;
      position: relative;
      cursor: pointer;
      font-weight: 300;
      display: flex;
      align-items: center; }
    .group-input-checkbox-shadow .styled-checkbox + label:before {
      content: '';
      margin-right: 13px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      min-width: 20px;
      min-height: 20px;
      border-radius: 3px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2); }
    .group-input-checkbox-shadow .styled-checkbox:hover + label:before {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.25); }
    .group-input-checkbox-shadow .styled-checkbox:checked + label:before {
      background: white; }
    .group-input-checkbox-shadow .styled-checkbox:disabled + label {
      color: #b8b8b8;
      cursor: auto; }
    .group-input-checkbox-shadow .styled-checkbox:disabled + label:before {
      box-shadow: none;
      background: #ddd; }
    .group-input-checkbox-shadow .styled-checkbox:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      background: #555;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #555, 4px 0 0 #555, 4px -2px 0 #555, 4px -4px 0 #555, 4px -6px 0 #555, 4px -8px 0 #555;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }

.form-input-oval-dropdown-group.dropdown {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px; }
  .form-input-oval-dropdown-group.dropdown:after {
    position: absolute;
    content: "\2193";
    color: rgba(0, 0, 0, 0.5);
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 15px;
    font-size: 2.5rem;
    font-weight: 100;
    line-height: 20px; }
  .form-input-oval-dropdown-group.dropdown .dropdownIcon {
    display: none; }
  .form-input-oval-dropdown-group.dropdown .form-input {
    font: inherit;
    background: none;
    background-color: white;
    color: black;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 11.5px 11px;
    display: block;
    width: 100%;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
    margin: 25px 0 0 0;
    border-radius: 100px; }
    .form-input-oval-dropdown-group.dropdown .form-input:focus {
      outline: none; }
    .form-input-oval-dropdown-group.dropdown .form-input:hover {
      box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.15); }
    .form-input-oval-dropdown-group.dropdown .form-input:focus ~ .form-input-label-off {
      display: none; }
  .form-input-oval-dropdown-group.dropdown input {
    font: inherit;
    font-size: 1.4rem;
    cursor: pointer;
    caret-color: transparent; }
  .form-input-oval-dropdown-group.dropdown input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400; }
  .form-input-oval-dropdown-group.dropdown .form-input-label {
    color: #999;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 14px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all; }
    .form-input-oval-dropdown-group.dropdown .form-input-label.shrink {
      color: black;
      top: -18px;
      font-size: 1.2rem;
      color: black; }

@-webkit-keyframes showOpacity {
  to {
    opacity: 1; } }

@keyframes showOpacity {
  to {
    opacity: 1; } }
  .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper {
    border: 0px;
    z-index: 10; }
    .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu {
      max-height: 200px;
      overflow-y: auto;
      width: calc(100% - 4px);
      padding: 10px;
      max-width: 100%;
      margin-right: 1px;
      margin-top: 1px;
      text-align: left;
      border-radius: 15px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-width: 0px;
      padding: 0px;
      cursor: pointer;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.1); }
      .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu:before {
        display: none; }
      .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu .dropdown-item {
        padding: 8px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1.4rem; }
        .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu .dropdown-item:hover {
          background-color: rgba(0, 0, 0, 0.015); }
        .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu .dropdown-item:active {
          background-color: rgba(0, 0, 0, 0.03); }
        .form-input-oval-dropdown-group.dropdown .collapsible-menu-wrapper .collapsible-menu .dropdown-item:last-child {
          border-bottom: none; }

.profile-info-form {
  position: relative; }
  .profile-info-form .btn-and-error-wrapper {
    position: relative; }
    .profile-info-form .btn-and-error-wrapper .error {
      text-align: right;
      color: #ff281e;
      position: absolute;
      right: 20px;
      top: -16px;
      font-size: 1.1rem; }
  .profile-info-form .social-media-header {
    text-align: center;
    padding-top: 15px;
    display: block;
    font-weight: 400;
    font-size: 1.5rem; }
  .profile-info-form .emptyList {
    margin-top: 5px; }
  .profile-info-form .notification {
    padding: 10px 10px 0px 10px;
    font-size: 1.2rem;
    line-height: 150%;
    text-align: left;
    color: #959595; }

.form-input-with-btn {
  position: relative; }
  .form-input-with-btn .form-input {
    padding-right: 45px !important; }
  .form-input-with-btn .form-input-with-btn__icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-3px);
            transform: translateY(-50%) translateX(-3px);
    right: -0.5%;
    background: white;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer; }
    .form-input-with-btn .form-input-with-btn__icon:hover {
      background: #fbfbfb; }
    .form-input-with-btn .form-input-with-btn__icon:active {
      background: #f6f6f6; }


.wrapper {
  display: flex;
  flex-direction: row;
  position: relative; }
  .wrapper .link-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left; }
    .wrapper .link-wrapper .media-name-and-icon {
      display: flex;
      flex-direction: row; }
      .wrapper .link-wrapper .media-name-and-icon .media-icon {
        margin-right: 4px;
        width: 10px;
        opacity: 0.4; }
    .wrapper .link-wrapper .link {
      font-size: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .wrapper .delete-btn {
    align-self: center;
    position: absolute;
    right: 0; }

.linkPlaceInfo {
  line-height: 200%; }
  .linkPlaceInfo img {
    margin-top: 5px;
    margin-bottom: -3px;
    width: 20px;
    height: 20px; }
  .linkPlaceInfo .arrowIcon {
    padding-right: 12px;
    padding-left: 12px;
    top: -2px;
    position: relative; }

.add-new-compareres {
  text-align: center; }
  .add-new-compareres .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 20rem 3rem 20rem; }
    @media only screen and (max-width: 1400px) {
      .add-new-compareres .cards-wrapper {
        padding: 3rem 10rem calc(3rem + 25px) 10rem; } }
    @media only screen and (max-width: 992px) {
      .add-new-compareres .cards-wrapper {
        padding: 3rem 3rem calc(3rem + 25px) 3rem; } }
    @media only screen and (max-width: 600px) {
      .add-new-compareres .cards-wrapper {
        padding: 3rem 1rem calc(3rem + 25px) 1rem; } }
    .add-new-compareres .cards-wrapper .card {
      width: 50%;
      padding: 15px; }
      @media only screen and (max-width: 768px) {
        .add-new-compareres .cards-wrapper .card {
          width: 70%; } }
      @media only screen and (max-width: 600px) {
        .add-new-compareres .cards-wrapper .card {
          width: 100%; } }
  .add-new-compareres .settings-wrapper {
    justify-content: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding-right: 23px;
    padding-left: 23px; }
    .add-new-compareres .settings-wrapper .custom-btn-wrapper {
      margin: 20px auto 50px auto; }
    .add-new-compareres .settings-wrapper .group-input-checkbox {
      margin-top: 0px; }
      .add-new-compareres .settings-wrapper .group-input-checkbox label {
        font-size: 1.4rem;
        font-weight: 400; }
    .add-new-compareres .settings-wrapper h3 {
      margin-top: 30px;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: left;
      margin-bottom: 0px !important; }
    .add-new-compareres .settings-wrapper .group-oval.textarea {
      margin-top: 0px;
      margin: 0px; }
      .add-new-compareres .settings-wrapper .group-oval.textarea .form-input-with-border {
        margin-top: 0px; }

.dropzone-wrapper {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }
  .dropzone-wrapper .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 2px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round;
    width: 100%;
    max-height: 100%; }
    .dropzone-wrapper .dropzone:after {
      content: "";
      display: block;
      padding-bottom: 80%; }
    .dropzone-wrapper .dropzone svg {
      pointer-events: none; }
    .dropzone-wrapper .dropzone .fileInput {
      display: none; }
  .dropzone-wrapper .highlight {
    background-color: whitesmoke;
    -webkit-animation: moveDash 1s;
            animation: moveDash 1s;
    border: dashed 40px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round; }
    .dropzone-wrapper .highlight:after {
      -webkit-transition-duration: 1s;
              transition-duration: 1s;
      content: "";
      display: block;
      padding-bottom: calc(80% - 15px); }

@-webkit-keyframes moveDash {
  from {
    border: dashed 2px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round; }
  to {
    border: dashed 40px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round; } }

@keyframes moveDash {
  from {
    border: dashed 2px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 2 round; }
  to {
    border: dashed 40px #000;
    -webkit-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round;
            border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA0CAYAAAAnpACSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgB7dqxDcIwEIXhF0gPG5AR2ACxASPQUbIaGzAKsEFG4E6JkOh8haOL9X/SJS5ykl93it1JuljtVW60eqi+8L56e9w1bXAsbdIyYU4qD+PfHX3xnBvXbLB6bdQQwmRFmKwIkxVhsmoqjPOZJjLQZfSbzQAAAAAAAAD821pdFTugzch/At58wQFtRoTJijBZESYrwmTVVJh+fvtluNLZ7G11Vn0+Zg2Rhk7TkLZTzEf1HRT0BW80E/STg2agAAAAAElFTkSuQmCC) 40 round; } }

.sign-in-form_footer {
  margin-top: 30px;
  font-size: 1.3rem; }
  .sign-in-form_footer .link {
    font-weight: bold; }

.signInForm .customButtonShadow,
.signInForm .customButtonShadow[type="submit"],
.signInForm .customButtonShadow[type="reset"] {
  width: 100% !important;
  justify-content: center; }

.sign-in-page .tiles-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center; }

.sign-up-page {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1680px;
  margin: auto;
  min-height: calc(100vh - 200px);
  align-content: center;
  align-items: center; }
  @media only screen and (max-width: 567px) {
    .sign-up-page .sign-up-tile {
      border-radius: 10px; } }
  .sign-up-page .popup {
    padding: 50px; }
    @media only screen and (max-width: 700px) {
      .sign-up-page .popup {
        padding: 30px; }
        .sign-up-page .popup .popup-body {
          padding-top: 30px; }
        .sign-up-page .popup .cancelIcon {
          top: 15px; } }
    @media only screen and (max-width: 400px) {
      .sign-up-page .popup {
        padding: 15px; }
        .sign-up-page .popup .cancelIcon {
          top: 10px; } }
    .sign-up-page .popup .row {
      padding-top: 30px;
      display: flex; }
      @media only screen and (max-width: 700px) {
        .sign-up-page .popup .row {
          flex-direction: column; } }
      .sign-up-page .popup .row .col50 {
        align-self: center; }
        @media only screen and (max-width: 700px) {
          .sign-up-page .popup .row .col50 {
            width: 100%; } }
        .sign-up-page .popup .row .col50 .popup-title {
          font-size: 1.8rem;
          text-align: left; }
        .sign-up-page .popup .row .col50 .popup-body {
          font-size: 1.5rem;
          text-align: left; }
    .sign-up-page .popup img {
      height: 400px; }
      @media only screen and (max-width: 700px) {
        .sign-up-page .popup img {
          margin-top: 20px;
          height: 300px; } }
      @media only screen and (max-width: 350px) {
        .sign-up-page .popup img {
          margin-top: 20px;
          height: auto;
          width: 100%; } }
  .sign-up-page .col50 {
    width: 50%;
    padding-right: 25px;
    padding-left: 25px; }
    @media only screen and (max-width: 900px) {
      .sign-up-page .col50 {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        text-align: center; }
        .sign-up-page .col50 .sign-up-form {
          margin-top: 20px;
          padding-bottom: 20px; } }
    @media only screen and (max-width: 900px) and (max-width: 567px) {
      .sign-up-page .col50 .sign-up-form p {
        font-size: 1.2rem !important; } }
    @media only screen and (max-width: 900px) {
        .sign-up-page .col50 .sign-up-tile {
          margin: auto; } }
    @media only screen and (max-width: 900px) and (max-width: 567px) {
      .sign-up-page .col50 .sign-up-tile {
        margin-top: 15px; }
        .sign-up-page .col50 .sign-up-tile .title {
          padding-top: 15px;
          padding-bottom: 10px;
          font-size: 1.6rem; } }
    .sign-up-page .col50 strong {
      font-weight: 500; }
    @media only screen and (max-width: 567px) {
      .sign-up-page .col50 {
        width: 100%;
        max-width: 350px;
        margin: auto; } }
  @media only screen and (max-width: 900px) {
    .sign-up-page .col50.left {
      justify-content: center;
      justify-self: center;
      justify-items: center; } }
  .sign-up-page .col50.right {
    text-align: right; }
    @media only screen and (max-width: 900px) {
      .sign-up-page .col50.right {
        display: none; } }
    .sign-up-page .col50.right img {
      width: 100%; }
  .sign-up-page h3 {
    font-size: 3.6rem;
    font-weight: 300;
    text-align: left; }
    @media only screen and (max-width: 900px) {
      .sign-up-page h3 {
        font-size: 2.6rem;
        text-align: center;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 567px) {
      .sign-up-page h3 {
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 10px; } }
  .sign-up-page .description {
    flex-direction: column;
    margin: auto;
    margin-bottom: 50px; }
    .sign-up-page .description a {
      text-decoration: none !important; }
    @media only screen and (max-width: 900px) {
      .sign-up-page .description {
        margin-bottom: 0px; }
        .sign-up-page .description .short-info {
          margin-top: 0px; } }
    @media only screen and (max-width: 567px) {
      .sign-up-page .description {
        padding-bottom: 0vh; } }
    @media only screen and (min-width: 500px) and (max-height: 420px) {
      .sign-up-page .description {
        margin-top: 5vh; } }
    .sign-up-page .description p {
      font-weight: 300;
      font-size: 1.9rem;
      padding: 10px 0 20px 0;
      line-height: 1.7; }
      @media only screen and (max-width: 1366px) {
        .sign-up-page .description p {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 1024px) {
        .sign-up-page .description p {
          font-size: 1.4rem; } }
      @media only screen and (max-width: 567px) {
        .sign-up-page .description p {
          font-size: 1.2rem;
          padding: 5px 0 10px 0; } }
    .sign-up-page .description .customButton {
      margin-left: 0px; }
      @media only screen and (max-width: 1366px) {
        .sign-up-page .description .customButton {
          font-size: 1.7rem; } }
      @media only screen and (max-width: 1024px) {
        .sign-up-page .description .customButton {
          font-size: 1.3rem; } }

.sign-up-tile {
  max-width: 430px;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 100px; }
  .sign-up-tile .title {
    text-align: center;
    font-size: 2rem;
    padding-top: 35px;
    font-weight: 400; }
  .sign-up-tile input {
    padding-right: 5%;
    padding-left: 5%; }
  .sign-up-tile .sign-up-email-form {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 40px; }
    .sign-up-tile .sign-up-email-form .group-oval.standardForm {
      margin-top: 15px; }
    .sign-up-tile .sign-up-email-form .customButtonShadow {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: 0px;
      text-align: center;
      justify-content: center; }
    .sign-up-tile .sign-up-email-form p {
      margin: 20px auto 0px 7px;
      font-size: 1.3rem;
      text-align: left;
      max-width: 300px;
      font-weight: 350; }
      .sign-up-tile .sign-up-email-form p a {
        font-weight: 500; }

.serviceUnavailable {
  font-size: 2rem;
  font-weight: 300;
  margin: auto; }

.please-sing-up {
  white-space: nowrap; }
  .please-sing-up p {
    text-align: left; }
    .please-sing-up p strong {
      font-weight: 500; }
  .please-sing-up ul {
    text-align: left; }
    .please-sing-up ul li {
      padding-top: 3px;
      padding-bottom: 3px; }

.vote-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 90%;
  overflow: hidden;
  margin: 0 auto; }
  .vote-page-wrapper strong {
    font-weight: 500; }
  .vote-page-wrapper .no-content {
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
    margin: auto;
    padding-right: 10%;
    padding-left: 10%;
    max-width: 1200px;
    line-height: 1.3;
    padding-bottom: 5%; }
  .vote-page-wrapper .vote-slider-wrapper {
    text-align: center; }

.vote-vertical {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  align-items: center; }
  @media only screen and (max-width: 702px) {
    .vote-vertical {
      padding-bottom: 30%; } }
  .vote-vertical .breakBarWrapperParent {
    padding-right: 5%;
    padding-left: 5%;
    width: calc(10% + 24px); }
    .vote-vertical .breakBarWrapperParent .breakBarWrapper {
      padding-right: 10px;
      padding-left: 10px;
      cursor: col-resize;
      width: 24px; }
      .vote-vertical .breakBarWrapperParent .breakBarWrapper .breakBar {
        width: 3px;
        background: black;
        height: 150px;
        border-radius: 2px;
        margin: auto;
        -webkit-animation: showBreakBar 0.7s;
                animation: showBreakBar 0.7s; }
        @media only screen and (max-width: 702px) {
          .vote-vertical .breakBarWrapperParent .breakBarWrapper .breakBar {
            -webkit-animation: showBreakBarShort 0.7s;
                    animation: showBreakBarShort 0.7s;
            height: 100px;
            width: 2.4px; } }
  .vote-vertical #pictureA,
  .vote-vertical #pictureB {
    padding: 3%;
    width: 70%;
    height: 70%;
    position: relative;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    touch-action: manipulation; }
    .vote-vertical #pictureA .heartIcon,
    .vote-vertical #pictureB .heartIcon {
      pointer-events: none; }
    .vote-vertical #pictureA img,
    .vote-vertical #pictureB img {
      width: 100px;
      height: auto;
      opacity: 0;
      position: absolute; }
  .vote-vertical #pictureA {
    width: calc(45% - 12px); }
  .vote-vertical #pictureB {
    flex: 1 1;
    width: 55%; }
  .vote-vertical .showIcon {
    background-size: contain !important; }
    .vote-vertical .showIcon img {
      opacity: 1 !important;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      -webkit-animation: showIcon 0.5s;
              animation: showIcon 0.5s; }
    .vote-vertical .showIcon::after {
      background: none !important; }
    .vote-vertical .showIcon:hover {
      background-size: contain !important; }

@-webkit-keyframes showIcon {
  0% {
    opacity: 0; }
  15% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  30% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes showIcon {
  0% {
    opacity: 0; }
  15% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  30% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes showBreakBarShort {
  0% {
    background: rgba(0, 0, 0, 0);
    -webkit-filter: blur(1px);
            filter: blur(1px);
    height: 100px; }
  20% {
    height: 150px;
    background: gray; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: black;
    height: 100px; } }

@keyframes showBreakBarShort {
  0% {
    background: rgba(0, 0, 0, 0);
    -webkit-filter: blur(1px);
            filter: blur(1px);
    height: 100px; }
  20% {
    height: 150px;
    background: gray; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: black;
    height: 100px; } }

@-webkit-keyframes showBreakBar {
  0% {
    height: 150px;
    background: rgba(0, 0, 0, 0);
    -webkit-filter: blur(1px);
            filter: blur(1px);
    @media only screen and (max-width: 702px) {
      height: 100px; } }
  20% {
    height: 200px;
    background: gray; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: black;
    height: 150px;
    @media only screen and (max-width: 702px) {
      height: 100px; } } }

@keyframes showBreakBar {
  0% {
    height: 150px;
    background: rgba(0, 0, 0, 0);
    -webkit-filter: blur(1px);
            filter: blur(1px);
    @media only screen and (max-width: 702px) {
      height: 100px; } }
  20% {
    height: 200px;
    background: gray; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: black;
    height: 150px;
    @media only screen and (max-width: 702px) {
      height: 100px; } } }

.comparere-about-wrapper, .comparere-about-wrapper-disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 8;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: -90px;
  position: relative;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s; }
  @media only screen and (max-width: 700px) {
    .comparere-about-wrapper, .comparere-about-wrapper-disabled {
      width: 50px;
      height: 50px; } }
  @media only screen and (max-width: 400px) {
    .comparere-about-wrapper, .comparere-about-wrapper-disabled {
      width: 40px;
      height: 40px; } }
  .comparere-about-wrapper-disabled {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); }
  .comparere-about-wrapper .context-icon, .comparere-about-wrapper-disabled .context-icon, .comparere-about-wrapper .context-icon-disabled, .comparere-about-wrapper-disabled .context-icon-disabled {
    cursor: pointer;
    z-index: 8; }
    .comparere-about-wrapper .context-icon-disabled, .comparere-about-wrapper-disabled .context-icon-disabled {
      opacity: 40%; }
  .comparere-about-wrapper .comparere-about, .comparere-about-wrapper-disabled .comparere-about {
    padding: 50px 30px 30px 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    background: white;
    max-width: 600px;
    width: 100vw;
    border-radius: 12px;
    -webkit-transform: translateX(calc(30px - 50%));
            transform: translateX(calc(30px - 50%));
    font-size: 1.4rem;
    line-height: 150%; }
  .comparere-about-wrapper .none, .comparere-about-wrapper-disabled .none {
    display: none; }

.popup-overlay-independent {
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  position: absolute;
  z-index: 3;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  background: rgba(255, 255, 255, 0);
  display: none; }

.popup-overlay-independent.active {
  background: rgba(255, 255, 255, 0.9);
  display: block; }

.compareres-with-votes-and-user-choice-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5rem 5px; }
  .compareres-with-votes-and-user-choice-wrapper .any-votes {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
    padding-left: 5px; }

.userChoiceIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .userChoiceIcons .iconContainer {
    min-width: 44px;
    max-width: 44px;
    margin-right: 7px;
    margin-left: 7px;
    margin-top: 6px; }
    @media only screen and (max-width: 700px) {
      .userChoiceIcons .iconContainer {
        margin-right: 4px;
        margin-left: 4px; } }

.pictures .your-choice-indicator {
  position: absolute;
  z-index: 99;
  bottom: 5px;
  left: calc(25% - (30px / 2));
  pointer-events: none; }
  @media only screen and (max-width: 700px) {
    .pictures .your-choice-indicator {
      left: calc(25% - (25px / 2)); } }
  .pictures .your-choice-indicator svg {
    width: 30px;
    height: 30px; }
    @media only screen and (max-width: 700px) {
      .pictures .your-choice-indicator svg {
        width: 25px;
        height: 25px; } }

.pictures .rightSide {
  left: calc(75% - (30px /2)) !important; }
  @media only screen and (max-width: 700px) {
    .pictures .rightSide {
      left: calc(75% - (25px /2)) !important; } }

.scope {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 60px; }
  .scope .line-wrapper {
    position: relative; }
    .scope .line-wrapper .line {
      position: relative;
      border-top: 1px solid #8c8b8b;
      top: 20px; }
    .scope .line-wrapper .circle {
      width: 35px;
      height: 35px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 7px; }

.reportIcon {
  width: 41px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 9; }
  @media only screen and (max-width: 700px) {
    .reportIcon {
      height: 3.6rem; } }
  .reportIcon:hover {
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px); }
  .reportIcon:active {
    -webkit-filter: blur(2.5px);
            filter: blur(2.5px); }

.vote-component-wrapper {
  height: calc(100vh - 80px); }

.hiddenPreloader {
  height: 1px;
  width: 1px; }
  .hiddenPreloader img {
    width: 10%;
    height: auto; }

.landing-page-wrapper .container {
  max-width: 1460px; }
  .landing-page-wrapper .container section {
    display: flex;
    padding-top: 10vh;
    padding-bottom: 10vh; }
    @media only screen and (max-width: 768px) and (min-height: 1024px) {
      .landing-page-wrapper .container section {
        padding-top: 15vh;
        padding-bottom: 5vh; } }
    @media only screen and (max-width: 567px) {
      .landing-page-wrapper .container section {
        flex-direction: column;
        padding-top: 5vh;
        padding-bottom: 7vh; } }
    .landing-page-wrapper .container section .col50 {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      padding-right: 25px;
      padding-left: 25px; }
      @media only screen and (max-width: 567px) {
        .landing-page-wrapper .container section .col50 {
          width: 100%;
          max-width: 350px;
          margin: auto; } }
    .landing-page-wrapper .container section .tabWrapper {
      justify-content: center; }
    .landing-page-wrapper .container section .description {
      flex-direction: column;
      padding-bottom: 20vh;
      margin: auto; }
      .landing-page-wrapper .container section .description a {
        text-decoration: none !important; }
      @media only screen and (max-width: 567px) {
        .landing-page-wrapper .container section .description {
          padding-bottom: 10vh; } }
      @media only screen and (min-width: 500px) and (max-height: 420px) {
        .landing-page-wrapper .container section .description {
          margin-top: 5vh; } }
      .landing-page-wrapper .container section .description p {
        font-weight: 300;
        font-size: 1.9rem;
        padding: 10px 0 30px 0;
        line-height: 1.7; }
        @media only screen and (max-width: 1366px) {
          .landing-page-wrapper .container section .description p {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 1024px) {
          .landing-page-wrapper .container section .description p {
            font-size: 1.4rem; } }
        @media only screen and (max-width: 567px) {
          .landing-page-wrapper .container section .description p {
            font-size: 1.2rem;
            padding: 5px 0 10px 0; } }
        @media only screen and (max-width: 320px) {
          .landing-page-wrapper .container section .description p {
            font-size: 1rem; } }
      .landing-page-wrapper .container section .description h1,
      .landing-page-wrapper .container section .description h2 {
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: 1px; }
        @media only screen and (max-width: 1366px) {
          .landing-page-wrapper .container section .description h1,
          .landing-page-wrapper .container section .description h2 {
            font-size: 3.4rem; } }
        @media only screen and (max-width: 1024px) {
          .landing-page-wrapper .container section .description h1,
          .landing-page-wrapper .container section .description h2 {
            font-size: 2.5rem; } }
        @media only screen and (max-width: 567px) {
          .landing-page-wrapper .container section .description h1,
          .landing-page-wrapper .container section .description h2 {
            font-size: 2rem; } }
        @media only screen and (max-width: 320px) {
          .landing-page-wrapper .container section .description h1,
          .landing-page-wrapper .container section .description h2 {
            font-size: 1.8rem; } }
      .landing-page-wrapper .container section .description .customButtonShadow {
        margin-left: 0px; }
        @media only screen and (max-width: 1366px) {
          .landing-page-wrapper .container section .description .customButtonShadow {
            font-size: 1.7rem; } }
        @media only screen and (max-width: 1024px) {
          .landing-page-wrapper .container section .description .customButtonShadow {
            font-size: 1.3rem; } }
    .landing-page-wrapper .container section .tabWrapper {
      display: flex;
      justify-content: center; }
    @media only screen and (max-width: 567px) {
      .landing-page-wrapper .container section:nth-child(2n) {
        flex-direction: column-reverse; } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.tabs-container-wrapper-box {
  overflow: hidden;
  padding-top: 10vh; }

.tabs-container-wrapper {
  overflow: hidden; }

.hideAnimation {
  opacity: 0;
  -webkit-transition-duration: 100ms;
          transition-duration: 100ms; }

.undertab {
  height: 100%;
  width: 100%;
  background: transparent;
  position: absolute;
  z-index: -1;
  top: 64px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  background: white; }
  .undertab .tabTabWrapper {
    height: 65px;
    margin-top: -63.5px; }
  .undertab .tabTab {
    height: 65px;
    width: calc(33.3333% * 2);
    background-repeat: no-repeat;
    background-size: 100% 65px;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }
  @media only screen and (max-width: 767px) {
    .undertab {
      top: 50px; }
      .undertab .tabTabWrapper {
        margin-top: -49px; }
      .undertab .tabTab {
        height: 51px;
        background-size: 100% 51px; } }

.tabs-container-wrapper {
  overflow: hidden;
  border-radius: 30px;
  justify-content: center;
  max-width: 550px;
  width: 100%;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1); }

.tabs-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 30px;
  height: auto;
  position: relative;
  z-index: 1; }
  .tabs-container .tab-title {
    width: 33.333%;
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    z-index: 100;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    overflow: hidden;
    -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .tabs-container .tab-title {
        height: 50px;
        line-height: 50px;
        font-size: 1.4rem; } }
  .tabs-container .tab-title.active {
    font-weight: 400; }
  .tabs-container .tab-description-container {
    height: 100%;
    width: 100%;
    min-height: 550px;
    padding: 50px;
    height: 70vw;
    max-height: 650px; }
    @media only screen and (max-width: 767px) {
      .tabs-container .tab-description-container {
        padding: 30px;
        max-height: 550px;
        min-height: 440px; } }
    @media only screen and (max-width: 567px) {
      .tabs-container .tab-description-container {
        padding: 40px; } }
    .tabs-container .tab-description-container .details-option {
      -webkit-animation: fadeInUp 0.3s ease-in-out 0s forwards;
              animation: fadeInUp 0.3s ease-in-out 0s forwards;
      display: flex;
      flex-direction: column;
      height: 100%; }
      @media only screen and (max-width: 567px) {
        .tabs-container .tab-description-container .details-option {
          padding: 0; } }
      .tabs-container .tab-description-container .details-option h3 {
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
        line-height: 1.7;
        margin: auto;
        font-weight: 400;
        margin-top: 0px; }
        @media only screen and (max-width: 767px) {
          .tabs-container .tab-description-container .details-option h3 {
            font-size: 1.4rem; } }
      .tabs-container .tab-description-container .details-option img {
        max-height: 440px;
        width: 100%;
        padding: 16px 8px 16px 8px; }
        @media only screen and (max-width: 567px) {
          .tabs-container .tab-description-container .details-option img {
            max-height: 300px;
            padding: 16px 8px 16px 8px; } }
      .tabs-container .tab-description-container .details-option .about {
        font-size: 2rem;
        font-weight: 300;
        margin: auto;
        line-height: 1.8;
        font-size: 1.6rem; }
        @media only screen and (max-width: 767px) {
          .tabs-container .tab-description-container .details-option .about {
            font-size: 1.4rem; } }

.designer-section {
  display: flex;
  padding: 5vh 25px 0 25px; }
  @media only screen and (max-width: 700px) {
    .designer-section {
      flex-direction: column; } }
  @media only screen and (max-width: 700px) {
    .designer-section .header-and-details-wrapper {
      text-align: center; } }
  .designer-section .header-and-details-wrapper .designer-of-day-header {
    width: 80%; }
  .designer-section .header-and-details-wrapper .login {
    font-size: 6rem;
    font-weight: 300;
    text-align: center;
    margin-top: 12px; }
    @media only screen and (max-width: 500px) {
      .designer-section .header-and-details-wrapper .login {
        font-size: 5rem; } }
  .designer-section .header-and-details-wrapper .media-icons-wrapper {
    width: 100%;
    text-align: center;
    vertical-align: baseline; }
    @media only screen and (max-width: 700px) {
      .designer-section .header-and-details-wrapper .media-icons-wrapper {
        padding: 8px 0 12px 0; } }
    .designer-section .header-and-details-wrapper .media-icons-wrapper .media-icon {
      margin: 6px;
      max-width: 18%;
      vertical-align: middle; }
  .designer-section .designer-of-day {
    width: 50%; }
    @media only screen and (max-width: 700px) {
      .designer-section .designer-of-day {
        align-self: center;
        padding-top: 24px;
        width: 60%; } }

.account-settings .underheader {
  text-align: center;
  font-size: 2rem; }

.account-settings .tiles-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 992px) {
    .account-settings .tiles-wrapper {
      justify-content: center; } }

.account-settings.container {
  max-width: 1720px; }

.promCode {
  padding-top: 30px;
  max-width: 300px;
  width: 100%;
  margin: auto; }
  .promCode button {
    width: 100%;
    justify-content: center; }

.payment-status .tiles-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .payment-status .tiles-wrapper .tileWrapper {
    width: 100%;
    min-width: auto;
    max-width: 1200px; }
    .payment-status .tiles-wrapper .tileWrapper .tile h2 {
      font-size: 4rem;
      font-weight: 300;
      padding-bottom: 60px; }
    .payment-status .tiles-wrapper .tileWrapper .tile p {
      font-size: 1.5rem; }

.fab-wrapper {
  position: fixed;
  height: auto;
  z-index: 99;
  cursor: pointer;
  -webkit-filter: blur(0.35px);
          filter: blur(0.35px); }
  .fab-wrapper .menu-wrapper {
    position: relative;
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1); }
  .fab-wrapper .info-fab-btn-wrapper img {
    height: 45px;
    width: auto; }
    @media only screen and (max-width: 700px) {
      .fab-wrapper .info-fab-btn-wrapper img {
        height: 4rem; } }
    @media only screen and (max-height: 400px) and (max-width: 812px) {
      .fab-wrapper .info-fab-btn-wrapper img {
        height: 38px; } }
  .fab-wrapper .info-fab-btn-wrapper:hover img {
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px); }
  .fab-wrapper .info-fab-btn-wrapper:active img {
    -webkit-filter: blur(2.5px);
            filter: blur(2.5px); }

.fab-wrapper-info {
  bottom: 20px;
  right: 20px; }

.fab-wrapper-alert {
  bottom: 80px;
  right: 20px;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all; }
  @media only screen and (max-width: 700px) {
    .fab-wrapper-alert {
      bottom: 65px; } }
  .fab-wrapper-alert img {
    -webkit-animation: toggleOpacity 3s infinite;
            animation: toggleOpacity 3s infinite; }
  .fab-wrapper-alert:hover img, .fab-wrapper-alert:active img, .fab-wrapper-alert:active img {
    -webkit-animation-duration: 0s;
            animation-duration: 0s;
    opacity: 1 !important; }

@-webkit-keyframes toggleOpacity {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.8; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes toggleOpacity {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.8; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.support-form {
  width: 350px !important; }

.contact-page {
  display: flex;
  flex-direction: column; }
  .contact-page .form-and-founders {
    display: flex;
    flex-wrap: wrap;
    margin: 80px 70px 80px 70px;
    justify-content: center; }
    @media only screen and (max-width: 640px) {
      .contact-page .form-and-founders {
        margin: 0;
        padding-top: 5rem; } }
    @media only screen and (max-width: 1490px) {
      .contact-page .form-and-founders .tileWrapper {
        width: 100%; } }
    .contact-page .form-and-founders .title {
      font-size: 2rem;
      font-style: normal;
      font-weight: normal; }
    .contact-page .form-and-founders .founders {
      width: 66.333%;
      padding: 0 0px 0 12%;
      position: relative; }
      @media only screen and (max-width: 1490px) {
        .contact-page .form-and-founders .founders {
          padding: 10% 0% 6% 0; } }
      .contact-page .form-and-founders .founders .founders-body {
        position: absolute;
        top: 0; }
        @media only screen and (max-width: 1490px) {
          .contact-page .form-and-founders .founders .founders-body {
            position: relative; } }
        .contact-page .form-and-founders .founders .founders-body .title {
          margin-bottom: 60px; }
          @media only screen and (max-width: 1490px) {
            .contact-page .form-and-founders .founders .founders-body .title {
              text-align: center; } }
        .contact-page .form-and-founders .founders .founders-body .founders-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center; }
          @media only screen and (max-width: 640px) {
            .contact-page .form-and-founders .founders .founders-body .founders-wrapper {
              flex-direction: column; } }
          .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder {
            display: flex;
            flex-direction: column;
            padding-right: 80px; }
            @media only screen and (max-width: 1490px) {
              .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder {
                padding-right: 60px; } }
            @media only screen and (max-width: 640px) {
              .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder {
                display: block;
                padding-right: 0px;
                padding-bottom: 34px;
                margin: 0 auto; } }
            .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder .founder-name {
              text-align: center;
              font-size: 1.2rem;
              text-transform: uppercase; }
            .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder .founder-contact-media {
              display: flex;
              flex-direction: row;
              justify-content: center; }
              .contact-page .form-and-founders .founders .founders-body .founders-wrapper .founder .founder-contact-media .media-icon {
                width: 24px;
                margin: 4px; }
          .contact-page .form-and-founders .founders .founders-body .founders-wrapper .last {
            padding-right: 0px; }
    @media only screen and (max-width: 1490px) {
      .contact-page .form-and-founders {
        justify-content: center; } }

.cookies-policy-page {
  -webkit-user-select: text !important;
     -moz-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important; }
  .cookies-policy-page .container {
    padding: 0vh 0vh 10vh 0vh;
    max-width: 1000px;
    font-size: 1.6rem;
    line-height: 1.4; }
    @media only screen and (max-width: 1064px) {
      .cookies-policy-page .container {
        padding: 0vh 10vw 10vh 10vw; } }
    .cookies-policy-page .container h3 {
      font-size: 2.4rem;
      padding-top: 80px;
      font-weight: 400; }
    .cookies-policy-page .container h4 {
      font-size: 2rem;
      margin-bottom: 0px;
      font-weight: 400; }
    .cookies-policy-page .container h5 {
      font-weight: 400;
      color: gray;
      text-align: center; }
    .cookies-policy-page .container p {
      margin-top: 5px;
      margin-bottom: 20px;
      font-weight: 300; }
    .cookies-policy-page .container a {
      font-weight: 400;
      font-style: italic; }

.privacy-policy-page {
  -webkit-user-select: text !important;
     -moz-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important; }
  .privacy-policy-page .container {
    padding: 0vh 0vh 10vh 0vh;
    max-width: 1000px;
    font-size: 1.6rem;
    line-height: 1.4; }
    @media only screen and (max-width: 1064px) {
      .privacy-policy-page .container {
        padding: 0vh 10vw 10vh 10vw; } }
    .privacy-policy-page .container h3 {
      font-size: 2.4rem;
      padding-top: 80px;
      font-weight: 400;
      text-transform: uppercase; }
    .privacy-policy-page .container h4 {
      font-size: 2rem;
      margin-bottom: 0px;
      font-weight: 400; }
    .privacy-policy-page .container h5 {
      font-weight: 400;
      color: gray;
      text-align: center; }
    .privacy-policy-page .container p {
      margin-top: 5px;
      margin-bottom: 20px;
      font-weight: 300; }
    .privacy-policy-page .container a {
      font-weight: 400;
      font-style: italic; }

.content-details {
  margin-left: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-weight: 300; }

.survey-qa-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 80px;
  max-width: 1200px; }
  .survey-qa-wrapper .title {
    font-size: 2rem;
    margin-bottom: 32px; }
  .survey-qa-wrapper .radio-buttons-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px; }
    @media only screen and (max-width: 900px) {
      .survey-qa-wrapper .radio-buttons-wrapper {
        flex-direction: column; } }
    .survey-qa-wrapper .radio-buttons-wrapper label {
      padding-top: 4px;
      font-size: 1.5rem;
      display: flex;
      line-height: 1.5;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      width: 100%; }
      .survey-qa-wrapper .radio-buttons-wrapper label input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        height: 20px;
        width: 20px;
        min-width: 20px;
        border: 1px solid black;
        border-radius: 50px;
        cursor: pointer;
        margin-right: 7px;
        outline: none;
        bottom: 1px; }
      .survey-qa-wrapper .radio-buttons-wrapper label input[type="radio"]:checked::before {
        position: absolute;
        font: 13px/1 "Open Sans", sans-serif;
        left: 6px;
        top: 2px;
        content: "\02143";
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg); }
      .survey-qa-wrapper .radio-buttons-wrapper label input[type="radio"]:hover {
        background-color: #f7f7f7; }
      .survey-qa-wrapper .radio-buttons-wrapper label span {
        padding: 0px 24px 0px 4px;
        vertical-align: middle; }

.survey-form {
  margin: 130px 10vw;
  text-align: unset; }
  .survey-form .submit-button-wrapper {
    width: 100%;
    display: flex; }
  .survey-form .email-input-wrapper {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding-top: 30px; }
    .survey-form .email-input-wrapper .email {
      margin: 4vh; }
    .survey-form .email-input-wrapper h3 {
      font-size: 1.5rem;
      text-align: center; }
  .survey-form .info-survey-email {
    font-size: 1.2rem;
    width: 100%;
    max-width: 500px;
    margin: auto;
    text-align: center;
    line-height: 170%; }

.not-found-wrapper {
  padding: 10vh 10vw 10vh 10vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: calc(100vh - 145px); }
  @media only screen and (max-width: 1366px) {
    .not-found-wrapper {
      min-height: calc(100vh - 135px); } }
  @media only screen and (max-width: 700px) {
    .not-found-wrapper {
      min-height: calc(100vh - 125px); } }
  .not-found-wrapper .details h1 {
    font-size: 4rem;
    margin-bottom: 4vh;
    font-weight: 400; }
  .not-found-wrapper .details h3 {
    font-size: 2.5rem;
    font-weight: 300; }
  .not-found-wrapper .not-found-404 {
    font-size: 16rem;
    font-weight: 200;
    margin-bottom: 5vh; }

.compareres-graph {
  margin: 0 auto 10rem auto;
  min-height: 390px; }

.graph-columns-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  text-align: center;
  max-width: 1200px;
  margin: auto;
  -webkit-animation: showAnimat 1s cubic-bezier(0.1, 0.9, 0.3, 0.9);
          animation: showAnimat 1s cubic-bezier(0.1, 0.9, 0.3, 0.9); }

@-webkit-keyframes showAnimat {
  0% {
    opacity: 0; }
  10% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes showAnimat {
  0% {
    opacity: 0; }
  10% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .graph-columns-wrapper .indicator {
    margin: 12px;
    width: 80px;
    font-size: 2rem;
    font-weight: 300;
    margin-right: 40px;
    margin-left: 40px;
    text-align: center; }
    @media only screen and (max-width: 700px) {
      .graph-columns-wrapper .indicator {
        margin-right: 15px;
        margin-left: 15px; } }
  .graph-columns-wrapper .graph-column {
    width: calc(50% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center; }
    .graph-columns-wrapper .graph-column .graph-column-details .nested {
      border: 1px solid black;
      border-radius: 4px;
      height: 8px; }
    .graph-columns-wrapper .graph-column .nested_description {
      padding-right: 10px;
      padding-left: 10px;
      font-size: 2rem;
      font-weight: 300; }
  .graph-columns-wrapper .graph-column.right .nested_description {
    margin-right: auto; }
  .graph-columns-wrapper .graph-column.left .nested_description {
    margin-left: auto; }

.comparere-details {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media only screen and (max-width: 700px) {
    .comparere-details {
      padding-top: 0; } }
  .comparere-details .context-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: 12px auto 12px auto; }
    .comparere-details .context-wrapper .context-data {
      font-weight: 300;
      font-size: 2rem;
      margin: 12px auto; }
  .comparere-details .info-not-enough-data {
    font-size: 1.6rem;
    line-height: 2;
    max-width: 600px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    margin: auto; }
    .comparere-details .info-not-enough-data strong {
      font-weight: 500; }
  .comparere-details .graph-change-btn-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 5vh 0 5vh 0; }
    .comparere-details .graph-change-btn-wrapper .btn-wrapper {
      margin: 0 24px 0 24px; }
  .comparere-details .arrows-points-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 0 24px 0;
    width: 34vw; }
    @media only screen and (max-width: 700px) {
      .comparere-details .arrows-points-wrapper {
        max-width: 46vw !important; } }
    .comparere-details .arrows-points-wrapper .total-votes {
      text-align: center;
      padding-top: 12px;
      font-weight: 300;
      font-size: 2rem;
      text-transform: uppercase; }
      .comparere-details .arrows-points-wrapper .total-votes .total-votes-count {
        font-weight: 300;
        font-size: 2.7rem; }
    .comparere-details .arrows-points-wrapper .points-wrapper {
      justify-content: center;
      text-align: center; }
      .comparere-details .arrows-points-wrapper .points-wrapper .points {
        font-weight: 300;
        font-size: 2.7rem; }
      .comparere-details .arrows-points-wrapper .points-wrapper .points.left {
        float: left;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .comparere-details .arrows-points-wrapper .points-wrapper .points.right {
        float: right;
        -webkit-transform: translateX(50%);
                transform: translateX(50%); }
  .comparere-details .images-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; }
    .comparere-details .images-wrapper .image {
      max-width: 30vw;
      height: auto;
      width: 100%; }
      @media only screen and (max-width: 700px) {
        .comparere-details .images-wrapper .image {
          max-width: 40vw; } }
    .comparere-details .images-wrapper .image.left {
      margin-right: 24px; }
      @media only screen and (max-width: 700px) {
        .comparere-details .images-wrapper .image.left {
          margin-right: 12px; } }
    .comparere-details .images-wrapper .image.right {
      margin-left: 24px; }
      @media only screen and (max-width: 700px) {
        .comparere-details .images-wrapper .image.right {
          margin-left: 12px; } }
  .comparere-details .btn-wrapper {
    text-align: center; }
  .comparere-details .btn-wrapper.arrow {
    margin-bottom: 3vh; }

.backButtonArrowBtnWrapper .arrow-btn {
  margin-right: 27px; }

.arrow-btn-component .arrow-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .arrow-btn-component .arrow-btn-wrapper .arrow.left {
    padding-right: 18px; }
  .arrow-btn-component .arrow-btn-wrapper .arrow.right {
    padding-left: 18px; }
  .arrow-btn-component .arrow-btn-wrapper .arrow-btn {
    color: inherit;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    font-size: 1.2rem;
    font-weight: 300; }

.context-edit-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 36px;
  position: relative;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  width: 100%;
  max-width: 600px;
  padding: 0 24px 0 24px; }
  .context-edit-wrapper .context-icon {
    position: absolute;
    left: 44%;
    top: -22%;
    z-index: 8; }
  .context-edit-wrapper .icon-wrapper {
    cursor: pointer;
    position: absolute;
    width: 36px;
    background: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    right: 7%;
    top: 23px;
    -webkit-filter: blur(0px);
            filter: blur(0px); }
    .context-edit-wrapper .icon-wrapper:hover {
      -webkit-filter: blur(0.5px);
              filter: blur(0.5px); }
    .context-edit-wrapper .icon-wrapper:active {
      -webkit-filter: blur(2.5px);
              filter: blur(2.5px); }
  .context-edit-wrapper .context-edit {
    padding: 12px 12px 12px 12px;
    left: 0;
    right: 0;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    line-height: 150%;
    border: 0.8px solid black; }
    .context-edit-wrapper .context-edit .form-input, .context-edit-wrapper .context-edit .form-input-disabled {
      width: 100%;
      height: 100%;
      border: none;
      resize: none;
      outline: none;
      background: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.55rem;
      letter-spacing: 1px; }
      .context-edit-wrapper .context-edit .form-input-disabled {
        color: #b3abab; }
  .context-edit-wrapper .none {
    display: none; }

.privacy-edit-wrapper .group-input-checkbox {
  margin-top: 0px; }
  .privacy-edit-wrapper .group-input-checkbox label {
    font-weight: 400; }

.group-input-checkbox {
  position: relative;
  margin-top: 6vh;
  display: flex; }
  .group-input-checkbox .styled-checkbox {
    position: absolute;
    font-size: 1.3rem;
    opacity: 0;
    height: 23px; }
    .group-input-checkbox .styled-checkbox + label {
      height: 23px;
      text-align: left;
      font-size: 1.3rem;
      position: relative;
      cursor: pointer;
      font-weight: 300;
      display: flex;
      align-items: center; }
    .group-input-checkbox .styled-checkbox + label:before {
      content: '';
      margin-right: 13px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid black;
      min-width: 20px;
      min-height: 20px;
      border-radius: 3px; }
    .group-input-checkbox .styled-checkbox:hover + label:before {
      background: whitesmoke; }
    .group-input-checkbox .styled-checkbox:checked + label:before {
      background: white; }
    .group-input-checkbox .styled-checkbox:disabled + label {
      color: #b8b8b8;
      cursor: auto; }
    .group-input-checkbox .styled-checkbox:disabled + label:before {
      box-shadow: none;
      background: #ddd; }
    .group-input-checkbox .styled-checkbox:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      background: black;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }

.accordion-item {
  margin: 12px 0px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border: solid 1px #eeeeee; }
  .accordion-item .wrapper {
    display: flex;
    flex-direction: row; }
    .accordion-item .wrapper .arrow-wrapper {
      position: relative;
      width: 10%;
      height: 100%;
      cursor: pointer; }
      .accordion-item .wrapper .arrow-wrapper .arrow {
        position: absolute;
        width: 20px;
        top: 24px;
        right: 24px;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out; }
        @media only screen and (max-width: 600px) {
          .accordion-item .wrapper .arrow-wrapper .arrow {
            width: 20px;
            right: 14px; } }
        .accordion-item .wrapper .arrow-wrapper .arrow.is-expanded {
          -webkit-transition: .5s;
          transition: .5s;
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .accordion-item .wrapper .title {
      padding: 20px 16px;
      width: 90%;
      cursor: pointer;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      color: black;
      position: relative;
      font-size: 2rem;
      background: white;
      text-align: left;
      border-bottom: none; }
  .accordion-item .content {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .6s;
    transition: max-height .6s;
    margin: 0;
    padding: 0px 20px;
    border-top: 0;
    background: white; }
    .accordion-item .content div {
      margin: 0;
      padding: 20px 0;
      -webkit-transition: .5s;
      transition: .5s;
      opacity: 0;
      font-size: 1.4rem; }
    .accordion-item .content.is-expanded {
      max-height: 500px;
      overflow: hidden; }
      .accordion-item .content.is-expanded div {
        opacity: 1; }

.accordion-container {
  min-height: 100%;
  padding: 30px;
  width: 60%;
  margin-top: 24px;
  font-family: 'Montserrat';
  text-align: left; }
  @media only screen and (max-width: 1000px) {
    .accordion-container {
      width: 100%;
      margin-bottom: 10%; } }
  @media only screen and (max-width: 600px) {
    .accordion-container {
      padding: 30px 0px 30px 0px; } }
  .accordion-container h1 {
    text-align: center;
    color: black; }
  .accordion-container .btn {
    display: inline-block;
    border: solid 1px #eeeeee;
    background: white;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 15px;
    cursor: pointer;
    outline: none; }
    .accordion-container .btn:hover {
      background: white;
      color: black;
      -webkit-transition: .5s;
      transition: .5s; }

.accordion {
  margin: 0 auto;
  width: 80%;
  background: white; }

.faq-page .accordion-form-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10vh;
  padding: 10vh 0 10vh 0; }
  @media only screen and (max-width: 1000px) {
    .faq-page .accordion-form-wrapper {
      flex-direction: column;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      padding: 0vh 0 10vh 0; } }

.sign-up-tile {
  max-width: 430px;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 100px; }
  .sign-up-tile .title {
    text-align: center;
    font-size: 2rem;
    padding-top: 35px;
    font-weight: 400;
    margin-bottom: 0px; }
  .sign-up-tile input {
    padding-right: 5%;
    padding-left: 5%; }
  .sign-up-tile .sign-up-password-form {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 40px;
    margin-top: 40px; }
    .sign-up-tile .sign-up-password-form .group-oval.standardForm {
      margin-top: 15px; }
    .sign-up-tile .sign-up-password-form .customButtonShadow {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: 0px;
      text-align: center;
      justify-content: center; }
    .sign-up-tile .sign-up-password-form .disable-a-href:hover {
      text-decoration: none !important; }
    .sign-up-tile .sign-up-password-form p {
      margin: 20px auto 0px 7px;
      font-size: 1.3rem;
      text-align: left;
      max-width: 300px;
      font-weight: 350; }
      .sign-up-tile .sign-up-password-form p a {
        font-weight: 500; }

.sign-up-tile {
  max-width: 430px;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 100px; }
  .sign-up-tile .title {
    text-align: center;
    font-size: 2rem;
    padding-top: 35px;
    font-weight: 400; }
  .sign-up-tile input {
    padding-right: 5%;
    padding-left: 5%; }
  .sign-up-tile .sign-up-password-form {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 40px;
    margin-top: 40px; }
    .sign-up-tile .sign-up-password-form .group-oval.standardForm {
      margin-top: 15px; }
    .sign-up-tile .sign-up-password-form .customButtonShadow {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: 0px;
      text-align: center;
      justify-content: center; }
    .sign-up-tile .sign-up-password-form .disable-a-href {
      font-size: 1.4rem;
      padding-top: 10px;
      margin-left: 10px;
      text-align: left;
      display: block;
      color: #aaa; }
      .sign-up-tile .sign-up-password-form .disable-a-href:hover {
        text-decoration: none !important; }
    .sign-up-tile .sign-up-password-form p {
      margin: 20px auto 0px 7px;
      font-size: 1.3rem;
      text-align: left;
      max-width: 300px;
      font-weight: 350; }
      .sign-up-tile .sign-up-password-form p a {
        font-weight: 500; }

