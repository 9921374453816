.user-compareres {
  .tiles-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .any-compareres {
      font-size: 2rem;
      font-weight: 300;

      .addIcon {
        height: 15px;
        margin-bottom: -2px;
      }
    }

  }

}

.user-compareres.container {
  max-width: 1720px;
}