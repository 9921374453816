.survey-form {
  margin: 130px 10vw;
  text-align: unset;

  .submit-button-wrapper {
    width: 100%;
    display: flex;
  }

  .email-input-wrapper {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding-top: 30px;

    .email {
      margin: 4vh;
    }

    h3 {
      font-size: 1.5rem;
      text-align: center;
    }

  }

  .info-survey-email {
    font-size: 1.2rem;
    width: 100%;
    max-width: 500px;
    margin: auto;
    text-align: center;
    line-height: 170%;
  }
}