@mixin font-format-27 {
  font-weight: 300;
  font-size: 2.7rem;
}

@mixin font-format-20-upper {
  font-weight: 300;
  font-size: 2rem;
  text-transform: uppercase;
}

@mixin font-format-20 {
  font-weight: 300;
  font-size: 2rem;
}

.comparere-details {
  padding-top: 5rem;
  padding-bottom: 5rem;
  // display: flex;
  // flex-direction: column;

  @media only screen and (max-width: 700px) {
    padding-top: 0;
  }

  .context-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: 12px auto 12px auto;

    .context-data {
      @include font-format-20;
      margin: 12px auto;

    }
  }
  .info-not-enough-data{
    font-size: 1.6rem;
    line-height: 2;
    max-width: 600px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    margin: auto;
    strong{
      font-weight: 500;
    }
  }
  .graph-change-btn-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 5vh 0 5vh 0;

    .btn-wrapper {
      margin: 0 24px 0 24px;
    }
  }

  .arrows-points-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 0 24px 0;
    width: 34vw;

    @media only screen and (max-width: 700px) {
      max-width: 46vw !important;
    }

    .total-votes {
      text-align: center;
      padding-top: 12px;
      @include font-format-20-upper;

      .total-votes-count {
        @include font-format-27;
      }
    }

    .points-wrapper {
      justify-content: center;
      text-align: center;

      .points {
        @include font-format-27;
      }

      .points.left {
        float: left;
        transform: translateX(-50%);
      }

      .points.right {
        float: right;
        transform: translateX(50%);
      }
    }
  }

  .images-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .image {
      max-width: 30vw;
      height: auto;
      width: 100%;

      @media only screen and (max-width: 700px) {
        max-width: 40vw;
      }
    }

    .image.left {
      margin-right: 24px;

      @media only screen and (max-width: 700px) {
        margin-right: 12px;
      }
    }

    .image.right {
      margin-left: 24px;

      @media only screen and (max-width: 700px) {
        margin-left: 12px;
      }
    }
  }

  .btn-wrapper {
    text-align: center;
  }

  .btn-wrapper.arrow {
    margin-bottom: 3vh;
  }
}

.backButtonArrowBtnWrapper {
  .arrow-btn {
    margin-right: 27px;
  }
}