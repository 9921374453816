.vote-vertical {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  align-items: center;

  @media only screen and (max-width: 702px) {
    padding-bottom: 30%;
  }

  .breakBarWrapperParent {
    padding-right: 5%;
    padding-left: 5%;
    width: calc(10% + 24px);

    .breakBarWrapper {
      padding-right: 10px;
      padding-left: 10px;
      cursor: col-resize;
      width: 24px;

      .breakBar {
        width: 3px;
        background: black;
        height: 150px;
        border-radius: 2px;
        margin: auto;
        animation: showBreakBar 0.7s;

        @media only screen and (max-width: 702px) {
          animation: showBreakBarShort 0.7s;
          height: 100px;
          width: 2.4px;
        }
      }
    }
  }

  #pictureA,
  #pictureB {
    padding: 3%;
    width: 70%;
    height: 70%;
    position: relative;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    touch-action: manipulation;

    .heartIcon {
      pointer-events: none;
    }

    img {
      width: 100px;
      height: auto;
      opacity: 0;
      position: absolute;
    }
  }

  #pictureA {
    width: calc(45% - 12px);
  }

  #pictureB {
    flex: 1;
    width: 55%;
  }

  .showIcon {
    img {
      opacity: 1 !important;
      user-select: none;
      animation: showIcon 0.5s;
    }

    background-size: contain !important;

    &::after {
      background: none !important;
    }

    &:hover {
      background-size: contain !important;
    }
  }
}

@keyframes showIcon {
  0% {
    opacity: 0;
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(0.85);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}


@keyframes showBreakBarShort {
  0% {
    background: rgba(0, 0, 0, 0);
    filter: blur(1px);
    height: 100px;
  }

  20% {
    height: 150px;
    background: gray;
  }

  100% {
    filter: blur(0px);
    background: black;
    height: 100px;
  }
}

@keyframes showBreakBar {
  0% {
    height: 150px;
    background: rgba(0, 0, 0, 0);
    filter: blur(1px);

    @media only screen and (max-width: 702px) {
      height: 100px;
    }
  }

  20% {
    height: 200px;
    background: gray;
  }

  100% {
    filter: blur(0px);
    background: black;
    height: 150px;

    @media only screen and (max-width: 702px) {
      height: 100px;
    }
  }
}