.navbar.showShadow {
  transition-duration: 1s;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
}

.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  background: white;
  z-index: 100;

  @media only screen and (max-width: 700px) {
    height: 70px;
  }

  @media only screen and (max-height: 400px) {
    height: 60px;
  }

  .container {
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    padding-right: 20px;
    padding-left: 20px;

    @media only screen and (max-width: 700px) {
      padding-right: 10px;
      padding-left: 10px;
      height: 70px;
    }

    @media only screen and (max-height: 400px) {
      height: 60px;
    }

    .col-left {
      width: 30%;
      @media only screen and (max-width: 700px) {
        width: 70%;
      }
      img {
        height: 45px;
        width: auto;
        padding: 0px 3px;


        &:hover {
          filter: blur(0.5px);
        }

        &:active {
          filter: blur(2.5px);
        }

        @media only screen and (max-width: 700px) {
          height: auto;
          width: 70%;
          &:hover {
            filter: none;
          }
        }

        @media only screen and (max-width: 400px) {
          width: 90%;
        }

        @media only screen and (max-height: 400px) and (max-width: 812px) {
          height: 40px;
          width: auto;
        }
      }
    }

    .col-middle {
      width: 40%;
      text-align: center;
      @media only screen and (max-width: 700px) {
        display: none;
      }
    
    }

    .col-right {
      width: 30%;
      display: flex;
      justify-content: flex-end;

      .iconWrapper_points {
        &:hover {
          filter: blur(0.5px);
        }

        &:active {
          filter: blur(2.5px);
        }
        .points-count-wrapper {
          overflow: hidden;
          // border: 0.8px solid black;
          width: 45px;
          height: 45px;
          border-radius: 100px;

          @media only screen and (max-width: 700px) {
            height: 4rem;
            width: 4rem;
            &:hover {
              filter: none;
            }
          }

          @media only screen and (max-height: 400px) and (max-width: 812px) {
            height: 38px;
            width: 38px;
          }

          a {
            text-decoration: none !important;
          }
        }
      }

      .points-count {
        font-weight: 300;
        text-align: center;
        line-height: 44px;
        font-size: 1.75rem;
        letter-spacing: 0.05em;

        @media only screen and (max-width: 700px) {
          line-height: 4rem;
        }

        @media only screen and (max-height: 400px) and (max-width: 812px) {
          line-height: 38px;
        }

        // @media only screen and (max-width: 700px) {
        //   font-size: 2.4rem;
        // }

        // @media only screen and (max-height: 400px) and (max-width: 812px) {
        //   font-size: 2.2rem;
        // }

        // @media only screen and (max-width: 400px) {
        //   display: none;
        // }
      }

      .addPointsAnim {
          fill: green;
          animation: changePoints 0.7s ease-in-out;
      }

      @keyframes changePoints {
        0% {
          opacity: 1;
          padding-left: 0px;
          padding-right: 0px;
        }

        49% {
          padding-right: 50px;
          padding-left: 0px;
          opacity: 0;
        }

        51% {
          padding-right: 0px;
          padding-left: 50px;
          opacity: 0;
        }

        100% {
          padding-left: 0px;
          padding-right: 0px;
          opacity: 1;
        }
      }

      .iconWrapper {
        padding: 6px;

        .user-details {
          text-transform: uppercase;
          font-weight: 500;
        }

        img {
          cursor: pointer;
          height: 45px;
          width: auto;
          filter: blur(0px);

          &:hover {
            filter: blur(0.5px);
          }

          &:active {
            filter: blur(2.5px);
          }

          @media only screen and (max-width: 700px) {
            height: 4rem;
          }

          @media only screen and (max-height: 400px) and (max-width: 812px) {
            height: 38px;
          }
        }
      }
    }

    .unautorized {
      font-size: 1.4rem;
      padding: 3px;
      margin-left: auto;

      @media only screen and (max-width: 400px) {
        flex-direction: column-reverse;
      }

      .break {
        margin: auto 0px auto 10px;
        padding: 8px 0px 8px 0px;
        color: #bbb;

        @media only screen and (max-width: 400px) {
          display: none;
        }
      }

      .aWrapper {
        margin-left: 10px;
        text-align: center;
        white-space: nowrap;

        &:first-of-type {
          margin: 8px 0px;
        }

        &:last-of-type {
          padding: 8px 12px;
          border: 1px solid black;
          border-radius: 5px;

          @media only screen and (max-width: 400px) {
            text-decoration: underline;
          }
        }

        @media only screen and (max-width: 400px) {
          margin: 4px 0px !important;
          font-weight: 1.2rem !important;
          padding: 0px 4px !important;
          border: none !important;
          text-align: right !important;
        }
      }

      .aWrapper.disabled {
        cursor: not-allowed;

        a {
          pointer-events: none;
        }
      }
    }

    @media only screen and (max-width: 700px) and (min-height: 400px) {
      .col {
        width: 50%;
        padding: 3.5%
      }
    }
  }
}