$sub-color: #999;
$main-color: black;

@mixin shrinkLabel {
  top: -18px;
  font-size: 1.2rem;
  color: $main-color;
}

.form-input-oval-dropdown-group.dropdown {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  &:after {
    position: absolute;
    content: "\2193";//"↓";
    color: rgba(0,0,0,0.5);
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 2.5rem;
    font-weight: 100;
    line-height: 20px;
  }

  .dropdownIcon {
    display: none;
  }

  .form-input {
    font: inherit;
    background: none;
    background-color: white;
    color: $main-color;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 11.5px 11px;
    display: block;
    width: 100%;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
    margin: 25px 0 0 0;
    border-radius: 100px;

    &:focus {
      outline: none;
    }
    
    &:hover{
      box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.15);
    }

    &:focus~.form-input-label-off {
      display: none;
    }
  }

  input {
    font: inherit;
    font-size: 1.4rem;
    cursor: pointer;
    caret-color: transparent;
  }

  input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400;
  }


  .form-input-label {
    color: $sub-color;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 14px;
    transition: 300ms ease all;

    &.shrink {
      color: $main-color;
      @include shrinkLabel();
    }
  }

  @keyframes showOpacity {
    to {
      opacity: 1;
    }
  }

  .collapsible-menu-wrapper {
    border: 0px;
    z-index: 10;

    .collapsible-menu {
      max-height: 200px;
      overflow-y: auto;
      width: calc(100% - 4px);
      padding: 10px;
      max-width: 100%;
      margin-right: 1px;
      margin-top: 1px;
      text-align: left;
      border-radius: 15px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-width: 0px;
      padding: 0px;
      cursor: pointer;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.1);


      &:before {
        display: none;
      }

      .dropdown-item {
        padding: 8px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1.4rem;

        &:hover {
          background-color: rgba(0, 0, 0, 0.015);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.03);
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}