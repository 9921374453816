.vote-component-wrapper {
  height: calc(100vh - 80px);
}

.hiddenPreloader {
  height: 1px;
  width: 1px;

  img {
    width: 10%;
    height: auto;
  }
}

.landing-page-wrapper {
  .container {
    max-width: 1460px;

    section {
      display: flex;
      padding-top: 10vh;
      padding-bottom: 10vh;


      @media only screen and (max-width: 768px) and (min-height: 1024px) {
        padding-top: 15vh;
        padding-bottom: 5vh;
      }

      @media only screen and (max-width: 567px) {
        flex-direction: column;
        padding-top: 5vh;
        padding-bottom: 7vh;
      }

      .col50 {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        padding-right: 25px;
        padding-left: 25px;

        @media only screen and (max-width: 567px) {
          width: 100%;
          max-width: 350px;
          margin: auto;

        }
      }

      .tabWrapper {
        justify-content: center;
      }

      .description {
        flex-direction: column;
        padding-bottom: 20vh;
        margin: auto;

        a {
          text-decoration: none !important;
        }

        @media only screen and (max-width: 567px) {
          padding-bottom: 10vh;
        }

        @media only screen and (min-width: 500px) and (max-height: 420px) {
          margin-top: 5vh;
        }

        p {
          font-weight: 300;
          font-size: 1.9rem;
          padding: 10px 0 30px 0;
          line-height: 1.7;

          @media only screen and (max-width: 1366px) {
            font-size: 1.8rem;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 1.4rem;
          }

          @media only screen and (max-width: 567px) {
            font-size: 1.2rem;
            padding: 5px 0 10px 0;
          }

          @media only screen and (max-width: 320px) {
            font-size: 1rem;
          }
        }

        h1,
        h2 {
          font-size: 4rem;
          font-weight: 400;
          letter-spacing: 1px;

          @media only screen and (max-width: 1366px) {
            font-size: 3.4rem;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 2.5rem;
          }

          @media only screen and (max-width: 567px) {
            font-size: 2rem;
          }

          @media only screen and (max-width: 320px) {
            font-size: 1.8rem;
          }
        }

        .customButtonShadow {
          margin-left: 0px;

          @media only screen and (max-width: 1366px) {
            font-size: 1.7rem;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 1.3rem;
          }
        }
      }

      .tabWrapper {
        display: flex;
        justify-content: center;
      }


      &:nth-child(2n) {
        @media only screen and (max-width: 567px) {
          flex-direction: column-reverse;
        }
      }
    }
  }
}