.promCode {
  padding-top: 30px;
  max-width: 300px;
  width: 100%;
  margin: auto;

  button {
    width: 100%;
    justify-content: center;
  }
}