.popup-overlay-independent {
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  position: absolute;
  z-index: 3;
  transition-duration: 0.3s;
  background: rgba(255, 255, 255, 0);
  display: none;
}

.popup-overlay-independent.active{
  background: rgba(255, 255, 255, 0.9);
  display: block;
}
