.designer-section {
  display: flex;
  padding: 5vh 25px 0 25px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  .header-and-details-wrapper {

    @media only screen and (max-width: 700px) {
      text-align: center;
    }
    
    .designer-of-day-header {
      width: 80%;
    }

    .login {
      font-size: 6rem;
      font-weight: 300;
      text-align: center;
      margin-top: 12px;

      @media only screen and (max-width: 500px) {
        font-size: 5rem;
      }
    }
  
    .media-icons-wrapper {
      width: 100%;
      text-align: center;
      vertical-align: baseline;

      @media only screen and (max-width: 700px) {
       padding: 8px 0 12px 0
      }

      .media-icon {
        margin: 6px;
        max-width: 18%;
        vertical-align: middle;
      }
    }
  }
  
  .designer-of-day {
    width: 50%;

    @media only screen and (max-width: 700px) {
      align-self: center;
      padding-top: 24px;
      width: 60%;
    }
  }
}