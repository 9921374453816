.sign-up-tile {
  max-width: 430px;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 100px;
  .title {
    text-align: center;
    font-size: 2rem;
    padding-top: 35px;
    font-weight: 400;
  }

  input {
    padding-right: 5%;
    padding-left: 5%;
  }

  .sign-up-password-form {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 40px;
    margin-top: 40px;

    .group-oval.standardForm {
      margin-top: 15px;
    }

    .customButtonShadow {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: 0px;
      text-align: center;
      justify-content: center;
    }
    .disable-a-href{
      font-size: 1.4rem;
      padding-top: 10px;
      margin-left: 10px;
      text-align: left;
      display: block;
      color: #aaa;
      &:hover{
        text-decoration: none !important;
      }
    }
    p {
      margin: 20px auto 0px 7px;
      font-size: 1.3rem;
      text-align: left;
      max-width: 300px;
      font-weight: 350;

      a {
        font-weight: 500;
      }
    }
  }
}