.popup-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;

  z-index: 999;

  .popup {
    flex-direction: column;
    flex-grow: inherit;
    height: auto;
    margin: auto;
    width: calc(100% - 10px);
    max-width: 850px;
    padding: 8rem 12rem;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    max-height: calc(100vh - 10px);
    overflow-y: auto;
    position: relative;

    @media only screen and (max-width: 1400px) {
    padding: 6rem 6rem;
    }
    @media only screen and (max-width: 700px) {
      padding: 6rem 3rem;
      }
  

    .cancelIcon {
      position: absolute;
      top: 30px;
      right: 28px;
      width: 15px;
      height: auto;
      cursor: pointer;
      &:hover {
        filter: blur(0.5px);
      }

      &:active {
        filter: blur(2.5px);
      }
    }

    .popup-title {
      font-size: 2rem;
      font-weight: 400;
    }

    .popup-body {
      padding-top: 50px;
      font-size: 1.8rem;
      font-weight: 300;
    }

    .popup-footer {
      padding-top: 50px;
      display: flex;
      justify-content: center;

      button {
        margin: 3px 13px;
      }
    }
  }
}