.linkPlaceInfo {
  line-height: 200%;
  
  img {
    margin-top: 5px;
    margin-bottom: -3px;
    width: 20px;
    height: 20px;
  }

  .arrowIcon {
    padding-right: 12px;
    padding-left: 12px;
    top: -2px;
    position: relative;
  }
}

.add-new-compareres {
  text-align: center;

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 20rem 3rem 20rem;

    @media only screen and (max-width: 1400px) {
      padding: 3rem 10rem calc(3rem + 25px) 10rem;
    }

    @media only screen and (max-width: 992px) {
      padding: 3rem 3rem calc(3rem + 25px) 3rem;
    }

    @media only screen and (max-width: 600px) {
      padding: 3rem 1rem calc(3rem + 25px) 1rem;
    }

    .card {
      width: 50%;
      padding: 15px;

      @media only screen and (max-width: 768px) {
        width: 70%;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .settings-wrapper {
    justify-content: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding-right: 23px;
    padding-left: 23px;

    .custom-btn-wrapper {
      margin: 20px auto 50px auto;
    }

    .group-input-checkbox{
      margin-top: 0px;
      label{
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    h3{
      margin-top: 30px;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: left;
      margin-bottom: 0px !important;
    }

    .group-oval.textarea{
      margin-top: 0px;
      margin: 0px;
      .form-input-with-border{
        margin-top: 0px;
      }
    }
  }
}