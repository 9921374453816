
.not-found-wrapper {
  padding: 10vh 10vw 10vh 10vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: calc(100vh - 145px);
  @media only screen and (max-width: 1366px) {
    min-height: calc(100vh - 135px);
  }

  @media only screen and (max-width: 700px) {
    min-height: calc(100vh - 125px);
  }

  .details {
    h1 {
      font-size: 4rem;
      margin-bottom: 4vh;
      font-weight: 400;
    }
  
    h3 {
      font-size: 2.5rem;
      font-weight: 300;
    }
  }
  .not-found-404 {
    font-size: 16rem;
    font-weight: 200;
    margin-bottom: 5vh;
  }
}

