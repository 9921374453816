.copyIcon {
  position: absolute;
  right: 0;
  top: 9px;
  width: 26px;
  cursor: pointer;
}

.shareInputWrapper {
  .group .form-input {
    padding-right: 35px;
  }
}

.user-comparere-tile {
  .user_comparere_link-wrapper {
    &:active {
      filter: blur(1px);
    }
  }
}

.tile {
  margin-bottom: 4rem;
  text-align: center;

  .smallTitle {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.05em;
  }

  .votesCount {
    font-size: 4rem;
    font-weight: 200;
    letter-spacing: 0.05em;
    margin-bottom: 30px;
    margin-top: 5px;
  }

  .selector {
    margin-top: 15px;
    margin-bottom: 3px;
    margin-right: 20px;
    margin-left: -20px;
    display: flex;

    .selectorItem {
      position: relative;
      width: 40px;
      font-weight: 400;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        font-weight: 300;
        margin: auto;
      }

      img {
        margin: auto;
        width: 26px;
      }
    }
  }

  .pictures {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    height: 260px;
    z-index: 2;

    @media only screen and (max-width: 700px) {
      height: 220px;
    }

    @media only screen and (max-width: 340px) {
      height: 180px;
    }


    .picture {
      width: calc(50% - 2px);
      height: 260px;

      @media only screen and (max-width: 700px) {
        height: 220px;
      }
      @media only screen and (max-width: 340px) {
        height: 180px;
      }
  

      margin: 1px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition-duration: 1s;
      cursor: zoom-out;

      &:active {
        transition-duration: 1s;
        background-size: contain;
        cursor: zoom-in;

        &::after {
          background: white;
        }
      }
    }
  }
}