.survey-qa-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 80px;
  max-width: 1200px;

  .title {
    font-size: 2rem;
    margin-bottom: 32px;
  }

  .radio-buttons-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }

    label {
      padding-top: 4px;
      font-size: 1.5rem;
      display: flex;
      line-height: 1.5;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      width: 100%;
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        height: 20px;
        width: 20px;
        min-width: 20px;
        border: 1px solid black;
        border-radius: 50px;
        cursor: pointer;
        margin-right: 7px;
        outline: none;
        bottom: 1px;
      }

      input[type="radio"]:checked::before {
        position: absolute;
        font: 13px/1 "Open Sans", sans-serif;
        left: 6px;
        top: 2px;
        content: "\02143";
        transform: rotate(40deg);
      }

      input[type="radio"]:hover {
        background-color: #f7f7f7;
      }

      span {
        padding: 0px 24px 0px 4px;
        vertical-align: middle;
      }
    }
  }
}