.contact-page {
  display: flex;
  flex-direction: column;

  .form-and-founders {
    display: flex;
    flex-wrap: wrap;
    margin: 80px 70px 80px 70px;
    justify-content: center;

    @media only screen and (max-width: 640px){
      margin: 0;
      padding-top: 5rem;
    }
    
    .tileWrapper {
      @media only screen and (max-width: 1490px){
        width: 100%;
      }
    }

    .title {
      font-size: 2rem;
      font-style: normal;
      font-weight: normal;
    }
    
    .founders {
      width: 66.333%;
      padding: 0 0px 0 12%;
      position: relative;

      @media only screen and (max-width: 1490px){
        padding: 10% 0% 6% 0;
      }

      .founders-body {
        position: absolute;
        top: 0;

        @media only screen and (max-width: 1490px){
          position: relative;
        }

        .title {
          margin-bottom: 60px;

          @media only screen and (max-width: 1490px){
            text-align: center;
          }
        }

        .founders-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          
          @media only screen and (max-width: 640px){
            flex-direction: column;
          }
      
          .founder {
            display: flex;
            flex-direction: column;
            padding-right: 80px;

            @media only screen and (max-width: 1490px){
              padding-right: 60px;
            }
            
            @media only screen and (max-width: 640px){
              display: block;
              padding-right: 0px;
              padding-bottom: 34px;
              margin: 0 auto;
            }

            .founder-name {
              text-align: center;
              font-size: 1.2rem;
              text-transform: uppercase;
            }
            
            .founder-contact-media {
              display: flex;
              flex-direction: row;
              justify-content: center;

              .media-icon {
                width: 24px;
                margin: 4px;
              }
            }
          }

          .last {
            padding-right: 0px;
          }
        }
      }
    }
    @media only screen and (max-width: 1490px){
      justify-content: center;
    }
  }
}