body {
  margin: 0px;
  overflow-y: scroll;
  user-select: none;
  opacity: 1;
  animation: showPage 0.8s;
  animation-iteration-count: 1;

  @keyframes showPage {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

footer {
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-bottom: 33px;
  position: relative;
  bottom: 0;
}

*::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.app-pages {
  margin-top: 80px;
  min-height: calc(100vh - 130px);
  padding-bottom: 30px;

  @media only screen and (max-width: 700px) {
    margin-top: 70px;
    min-height: calc(100vh - 120px);
  }

  @media only screen and (max-height: 400px) {
    margin-top: 60px;
    min-height: calc(100vh - 110px);
  }
}

a {
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    filter: blur(1px);
  }
}

@media only screen and (max-width: 400px) {
  .toasts-container {
    width: 80% !important;
  }
}

.toasts-container {

  .toast {
    animation-duration: 0.3s !important;
    font-size: 1.3rem;
    margin-right: 20px;
    border-radius: 100px !important;
    padding: 7px 45px !important;
    color: white !important;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 400px) {
      width: calc(100% - 50px) !important;
      justify-content: center !important;
      margin: auto !important;
      padding: 5px 30px !important;
      min-height: 40px !important;
    }
  }
}

.container {
  max-width: 1680px;
  margin: auto;
}

* {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}