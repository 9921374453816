.form-input-with-btn {
  position: relative;

  .form-input {
    padding-right: 45px !important;
  }

  .form-input-with-btn__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-3px);
    right: -0.5%;
    background: white;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:hover {
      background: #fbfbfb;
    }

    &:active {
      background: #f6f6f6;
    }
  }
}