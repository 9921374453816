@mixin fontDetails {
  font-size: 2rem;
  font-weight: 300;
}

.graph-columns-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  text-align: center;
  max-width: 1200px;
  margin: auto;

  animation: showAnimat 1s cubic-bezier(.1, .9, .3, .9);

  @keyframes showAnimat {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .indicator {
    margin: 12px;
    width: 80px;
    @include fontDetails;
    margin-right: 40px;
    margin-left: 40px;
    text-align: center;

    @media only screen and (max-width: 700px) {
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .graph-column {
    width: calc(50% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;

    .graph-column-details {
      .nested {
        border: 1px solid black;
        border-radius: 4px;
        height: 8px;
      }
    }

    .nested_description {
      padding-right: 10px;
      padding-left: 10px;
      @include fontDetails;
    }
  }

  .graph-column.right {
    .nested_description {
      margin-right: auto;
    }
  }

  .graph-column.left {
    .nested_description {
      margin-left: auto;
    }
  }
}