.popup-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;

  &-absolute {
    @extend .popup-overlay;
    position: absolute;
    height: 80vh;
    transform: translateX(-48vw) translateY(5vh);
    width: calc(100vw - 20px);
  }
}