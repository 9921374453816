.cookies-policy-page {
  user-select: text !important;

  .container {
    padding: 0vh 0vh 10vh 0vh;
    max-width: 1000px;
    font-size: 1.6rem;
    line-height: 1.4;

    @media only screen and (max-width: 1064px) {
      padding: 0vh 10vw 10vh 10vw;
    }

    h3 {
      font-size: 2.4rem;
      padding-top: 80px;
      font-weight: 400;
    }

    h4 {
      font-size: 2rem;
      margin-bottom: 0px;
      font-weight: 400;
    }

    h5{
      font-weight: 400;
      color: gray;
      text-align: center;
    }

    p {
      margin-top: 5px;
      margin-bottom: 20px;
      font-weight: 300;
    }

    a{
      font-weight: 400;
      font-style: italic;
    }
  }
}