.profile-info-form {
  position: relative;

  .btn-and-error-wrapper{
    position: relative;

    .error {
      text-align: right;
      color: rgba(255, 40, 30, 1);
      position: absolute;
      right: 20px;
      top: -16px;
      font-size: 1.1rem;
    }
  }

  .social-media-header {
    text-align: center;
    padding-top: 15px;
    display: block;
    font-weight: 400;
    font-size: 1.5rem;
  }

  .emptyList {
    margin-top: 5px;
  }

  .notification {
    padding: 10px 10px 0px 10px;
    font-size: 1.2rem;
    line-height: 150%;
    text-align: left;
    color: #959595;
  }
}