.fab-wrapper {
  position: fixed;
  height: auto;
  z-index: 99;
  cursor: pointer;
  filter: blur(0.35px);

  .menu-wrapper {
    position: relative;
    transform: scaleY(-1)
  }

  .info-fab-btn-wrapper {

    img {
      height: 45px;
      width: auto;

      @media only screen and (max-width: 700px) {
        height: 4rem;
      }

      @media only screen and (max-height: 400px) and (max-width: 812px) {
        height: 38px;
      }
    }

    &:hover {
      img {
        filter: blur(0.5px);
      }
    }

    &:active {
      img {
        filter: blur(2.5px);
      }
    }
  }
}

.fab-wrapper-info {
  bottom: 20px;
  right: 20px;
}

.fab-wrapper-alert {
  bottom: 80px;
  right: 20px;
  transition: 300ms ease all;

  @media only screen and (max-width: 700px) {
    bottom: 65px;
  }

  img {
    animation: toggleOpacity 3s infinite;
  }

  &:hover,
  &:active,
  &:active {
    img {
      animation-duration: 0s;
      opacity: 1 !important;
    }
  }
}

@keyframes toggleOpacity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.8;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}