.accordion-container {
  min-height: 100%;
  padding: 30px;
  width: 60%;
  margin-top: 24px;
  font-family: 'Montserrat';
  text-align: left;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 10%;
  }

  @media only screen and (max-width: 600px) {
    padding: 30px 0px 30px 0px;
  }

  h1 {
    text-align: center;
    color: black;
  }
  
  .btn {
    display: inline-block;
    border: solid 1px #eeeeee;
    background: white;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    
    &:hover {
      background: white;
      color: black;
      transition: .5s;
    }
  }
}

.accordion {
  margin: 0 auto;
  width: 80%;
  background: white;
}


