.group-input-checkbox-shadow {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;

  .styled-checkbox {
    position: absolute;
    font-size: 1.3rem;
    opacity: 0;
    height: 23px;

    &+label {
      height: 23px;
      text-align: left;
      font-size: 1.3rem;
      position: relative;
      cursor: pointer;
      font-weight: 300;
      display: flex;
      align-items: center;
    }

    &+label:before {
      content: '';
      margin-right: 13px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      min-width: 20px;
      min-height: 20px;
      border-radius: 3px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);

    }

    &:hover+label:before {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.25);
    }

    &:checked+label:before {
      background: white;
    }

    &:disabled+label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled+label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked+label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      background: #555;
      width: 2px;
      height: 2px;
      box-shadow:
        2px 0 0 #555,
        4px 0 0 #555,
        4px -2px 0 #555,
        4px -4px 0 #555,
        4px -6px 0 #555,
        4px -8px 0 #555;
      transform: rotate(45deg);
    }
  }
}