.arrow-btn-component {
  .arrow-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .arrow.left {
      padding-right: 18px;
    }

    .arrow.right {
      padding-left: 18px;
    }

    .arrow-btn {
      color: inherit;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}