html{
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1366px) {
  html{
    font-size: 9px;
  }
}

@media only screen and (max-width: 812px) {
  html{
    font-size: 8px;
  }
}

*,
:before,
:after {
  box-sizing: border-box;
}