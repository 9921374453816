@mixin font2rem {
  font-size: 2rem;
  font-weight: 300;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    // transform: translate3d(0, 5%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.tabs-container-wrapper-box {
  overflow: hidden;
  padding-top: 10vh;
}

.tabs-container-wrapper {
  overflow: hidden;
}

.hideAnimation {
  opacity: 0;
  transition-duration: 100ms;
}

.undertab {
  height: 100%;
  width: 100%;
  background: transparent;

  position: absolute;
  z-index: -1;
  top: 64px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .1));
  background: white;

  .tabTabWrapper {
    height: 65px;
    margin-top: -63.5px;
  }

  .tabTab {
    height: 65px;
    width: calc(33.3333% * 2);
    background-repeat: no-repeat;
    background-size: 100% 65px;
    transition-duration: 0.3s;
  }

  @media only screen and (max-width: 767px) {
    top: 50px;

    .tabTabWrapper {
      margin-top: -49px;
    }

    .tabTab {
      height: 51px;
      background-size: 100% 51px;
    }
  }
}

.tabs-container-wrapper {
  overflow: hidden;
  border-radius: 30px;
  justify-content: center;
  max-width: 550px;
  width: 100%;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
}

.tabs-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 30px;
  height: auto;
  position: relative;
  z-index: 1;

  .tab-title {
    width: 33.333%;
    height: 65px;
    line-height: 65px;
    text-align: center;
    @include font2rem;
    z-index: 100;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    overflow: hidden;
    transition-duration: 0.25s;
    cursor: pointer;

    @media only screen and (max-width: 767px) {
      height: 50px;
      line-height: 50px;
      font-size: 1.4rem;
    }
  }

  .tab-title.active {
    font-weight: 400;
  }


  .tab-description-container {
    height: 100%;
    width: 100%;
    min-height: 550px;
    padding: 50px;
    height: 70vw;
    max-height: 650px;

    @media only screen and (max-width: 767px) {
      padding: 30px;
      max-height: 550px;
      min-height: 440px;
    }

    @media only screen and (max-width: 567px) {
      padding: 40px;
    }

    .details-option {
      animation: fadeInUp 0.3s ease-in-out 0s forwards;
      display: flex;
      flex-direction: column;
      height: 100%;

      @media only screen and (max-width: 567px) {
        padding: 0;
      }

      h3 {
        @include font2rem();
        text-align: center;
        line-height: 1.7;
        margin: auto;
        font-weight: 400;
        margin-top: 0px;
        @media only screen and (max-width: 767px) {
          font-size: 1.4rem;
        }
      }

      img {
        max-height: 440px;
        width: 100%;
        padding: 16px 8px 16px 8px;
        @media only screen and (max-width: 567px) {
          max-height: 300px;
          padding: 16px 8px 16px 8px;
        }
      }

      .about {
        @include font2rem();
        margin: auto;
        line-height: 1.8;
        font-size: 1.6rem;

        @media only screen and (max-width: 767px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}