.aesthetic-indicator {
  display: flex;
  align-content: center;
  align-self: center;
  align-items: center;
  height: 46px;
  cursor: help;

  @media only screen and (max-width: 700px) {
    height: 4rem;
    display: none;
  }

  @media only screen and (max-height: 400px) and (max-width: 812px) {
    height: 38px;
  }

  .individual,
  .majority {
    margin-top: auto;

    img {
      width: 45px;
      height: 45px;

      @media only screen and (max-width: 700px) {
        height: 4rem;
        width: 4rem;
      }

      @media only screen and (max-height: 400px) and (max-width: 812px) {
        height: 38px;
        width: 38px;
      }
    }
  }

  .progress-bar-wrapper {
    width: 100%;

    .progress-bar {
      border-bottom: 0.8px solid black;
      height: 1px;
      margin-right: 18px;
      margin-left: 18px;
      width: calc(100% - 36px);
      display: flex;
      flex-direction: column;
      position: relative;

      @media only screen and (max-width: 700px) {
        margin-right: 14px;
        margin-left: 14px;
        width: calc(100% - 28px);
      }

      .hotIndicator {
        min-width: 36px;
        max-width: 36px;
        position: absolute;
        // left: 50%;
        transform: translateY(-18px) translateX(-18px);
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

        @media only screen and (max-width: 700px) {
          min-width: 28px;
          max-width: 28px;
          transform: translateY(-14px) translateX(-14px);
        }
      }
    }
  }
}