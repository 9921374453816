.comparere-about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 8;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: -90px;
  position: relative;
  transition-duration: 0.2s;

  @media only screen and (max-width: 700px) {
    width: 50px;
    height: 50px;
  }

  @media only screen and (max-width: 400px) {
    width: 40px;
    height: 40px;
  }

  &-disabled {
    @extend .comparere-about-wrapper;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .context-icon {
    cursor: pointer;
    z-index: 8;

    &-disabled {
      @extend .context-icon;
      opacity: 40%;
    }
  }

  .comparere-about {
    padding: 50px 30px 30px 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    background: white;
    max-width: 600px;
    width: 100vw;
    border-radius: 12px;
    transform: translateX(calc(30px - 50%));
    font-size: 1.4rem;
    line-height: 150%;
  }

  .none {
    display: none;
  }
}