.collapsible-menu-wrapper {
  background: black;
  display: flex;
  width: inherit;
  position: relative;
  justify-content: center;

  .collapsible-menu {
    margin-top: 10px;
    position: absolute;
    border: 1px solid black;
    border-radius: 5px;
    background: white;
    max-width: 300px;
    padding: 30% 60%;
    right: 1px;

    .link-wrapper {
      text-align: center;
      font-size: 1.3rem;
      padding: 8px 0px;
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }

      span {
        user-select: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        &:active {
          filter: blur(1px);

          @media only screen and (max-width: 800px) {
            filter: blur(0px);
          }
        }
      }
    }

    .position-over {
      transform: scaleY(-1);  
    }

    .user-details {
      text-align: center;
      font-size: 1.3rem;
      padding: 8px 0px 8px;
      margin-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      border-bottom: 1px solid rgb(202, 202, 202);
    }

    &:before {
      left: auto;
      right: 14px;

      @media only screen and (max-width: 700px) {
        right: 6px;
      }

      top: -16px;
      content: "";
      display: inline-block;
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: rgba(27, 31, 35, 1);
    }

    a {
      white-space: nowrap;
    }
  }
}